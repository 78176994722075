import React from 'react'
import { Form, Row, Col } from 'antd'
import { DdnSelect, FormInput, PrimaryButton } from 'shared/components'
import states from './states'

const MAILTO_EMAIL_ADDRESS = "CardRequests@discountdrugnetwork.com"

const rowProps = {
  type: 'flex',
  gutter: 14
}

const colMdProps = {
  xs: 24,
  md: 12
}

const colSmProps = {
  xs: 24,
  md: 6
}

const MailCardForm = () => {
  const [form] = Form.useForm()

  const handleMailCard = (values) => {
    const { name, email, address, apt = undefined, city, state, zip, number } = values
    const mailtoSubject = encodeURIComponent(name + ' - ' + email + ' - ' + number)
    const streetAddress = apt ? address + ', ' + apt : address
    const mailtoBody = encodeURIComponent(streetAddress + ', ' + city + ', ' + state + ' ' + zip)
    const mailtoQueryParams = `?subject=${mailtoSubject}&body=${mailtoBody}`
    window.open(`mailto:${MAILTO_EMAIL_ADDRESS}${mailtoQueryParams}`)
  }

  return (
    <div className='c-card-form'>
      <div className='c-card-form__title'>Have a card mailed to you</div>
      <div className='c-card-form__sub-title'>
        Please allow 7-10 business days for your card to arrive
      </div>
      <Form
        form={form}
        onFinish={handleMailCard}
      >
        <Row {...rowProps}>
          <Col {...colMdProps}>
            <Form.Item name='name' rules={[{ required: true, message: 'Please enter name' }]}>
              <FormInput label='FIRST AND LAST NAME' placeholder='Ex. John Dough' />
            </Form.Item>
          </Col>
          <Col {...colMdProps}>
            <Form.Item name='email' rules={[{ required: true, message: 'Please enter email' }]}>
              <FormInput label='EMAIL ADDRESS' placeholder='Ex. john.dough@email.com' type='email' />
            </Form.Item>
          </Col>
        </Row>
        <Row {...rowProps}>
          <Col {...colMdProps}>
            <Form.Item name='address' rules={[{ required: true, message: 'Please enter address' }]}>
              <FormInput label='MAILING ADDRESS' placeholder='PO Box or Street Address' />
            </Form.Item>
          </Col>
          <Col {...colMdProps}>
            <Form.Item name='apt'>
              <FormInput label='APT # (OPTIONAL)' placeholder='Ex. 201' />
            </Form.Item>
          </Col>
        </Row>
        <Row {...rowProps}>
          <Col {...colMdProps}>
            <Form.Item name='city' rules={[{ required: true, message: 'Please enter city' }]}>
              <FormInput label='CITY' placeholder='City Name' />
            </Form.Item>
          </Col>
          <Col {...colSmProps}>
            <Form.Item name='state' rules={[{ required: true, message: 'Please select state' }]}>
              <DdnSelect
                showSearch
                full
                label='STATE'
                placeholder='Select' 
                field='Value' 
                id='Text' 
                items={states}
                filterOption={(input, option) =>
                  option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                }
              />
            </Form.Item>
          </Col>
          <Col {...colSmProps}>
            <Form.Item name='zip' rules={[{ required: true, message: 'Please enter zipcode' }]}>
              <FormInput label='ZIP CODE' placeholder='Zip Code' />
            </Form.Item>
          </Col>
        </Row>
        <Row {...rowProps}>
          <Col {...colMdProps}>
            <Form.Item name='number' initialValue={1}>
              <FormInput label='NUMBER OF CARDS' type='number' min={1} />
            </Form.Item>
          </Col>
        </Row>
        <PrimaryButton className='c-card-form__submit' type='submit'>
          Send
        </PrimaryButton>
      </Form>
    </div>
  )
}

export default MailCardForm
