import { ArrowLeftOutlined } from '@ant-design/icons'
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { findDrugs } from 'features/search/redux/searchActions'
import {
  selectDrugsResults,
  selectDiscountInfo,
  selectLoadingCoupon,
  selectFilterInputs,
  selectDrugName
} from 'features/search/redux/searchSelector'
import { selectIframeGroupId } from 'features/auth/redux/authSelector'
import { queryStringify, parseQueryString, numberWithCommas } from 'utils'
import { setFrameHeight } from 'utils/iframe'
import { ListLoading } from 'shared/components'
// import { textGroupID } from 'shared/constants'
import CouponSend from '../CouponSend'

const CouponDetail = ({ isFrame }) => {
  const [pharmacyName, setPharmacy] = useState('')
  const [backQuery, setBackQuery] = useState({})
  const dispatch = useDispatch()
  const history = useHistory()
  const drugResults = useSelector(selectDrugsResults)
  const drugData = useSelector(selectDrugName)
  const genericName = drugData?.drugName;
  const discountInfo = useSelector(selectDiscountInfo)
  const filterInputs = useSelector(selectFilterInputs)
  const isLoadingCoupon = useSelector(selectLoadingCoupon)
  const iframeGroupId = useSelector(selectIframeGroupId)
  const [gId, setGId] = useState(null);

  let drug = null
  drugResults.forEach(({ drugsGroup }) => {
    const group = drugsGroup[pharmacyName]
    if (Array.isArray(group) && group.length > 0) {
      drug = group[0]
    }
  })

  useEffect(() => {
    (async () => {
      const search = history.location.search
      if (!search) {
        history.push('/')
        return
      }
      const query = parseQueryString(search)
      const { pharmacy, ..._query } = query
      const { zip, drugName, gsn, groupId } = _query
      const searchAvailable = zip && (drugName || gsn)
      const queryStr = queryStringify(query)

      if (!searchAvailable || !pharmacy) {
        history.push(searchAvailable ? `/get-discount${queryStr}` : '/')
        return
      }

      setGId(groupId)
      setPharmacy(pharmacy)
      setBackQuery(_query)
      if (!drugResults.length) {
        await dispatch(findDrugs(query))
      }
    })()
  }, [dispatch, history, drugResults])

  useEffect(() => {
    if ((isFrame && drug) || !history.location.search) {
      setFrameHeight()
    }
  }, [isFrame, drug, history.location.search])

  const handleBackToPrices = () => {
    const queryStr = queryStringify(backQuery)
    history.push(`/get-discount${queryStr}`)
  }

  const renderDetail = () => {
    if (isLoadingCoupon) {
      return <ListLoading />
    }

    if (!discountInfo || !drug) {
      return null
    }

    const { groupID, rxBIN, rxPCN } = discountInfo
    const { price } = drug
    const { form, strength, quantity } = filterInputs

    const discountGroupId = gId || iframeGroupId || groupID
    // const msgGroupId = iframeGroupId || textGroupID
    const discountInfoRows = [
      { label: 'BIN #:', value: rxBIN },
      { label: 'PCN:', value: rxPCN },
      { label: 'GROUP #:', value: discountGroupId },
      { label: 'CARD HOLDER ID:', value: discountGroupId }
    ]

    const messageBody = {
      "phoneNumber": "",
      "PharmacyName": pharmacyName,
      "DrugName": genericName,
      "DoseStrength": strength,
      "Quantity": quantity,
      "DrugForm": form,
      "DrugPrice": price && `$${numberWithCommas(price.toFixed(2))}`,
      "RxBin": rxBIN,
      "RxPcn": rxPCN,
      "GroupId": discountGroupId,
      "CardHolderId": discountGroupId
    }

    const emailBody = {
      "emailAddress": "support@discountdrugnetwork.com",
      "PharmacyName": pharmacyName,
      "DrugName": genericName,
      "DoseStrength": strength,
      "Quantity": quantity,
      "DrugForm": form,
      "DrugPrice": price && `$${numberWithCommas(price.toFixed(2))}`,
      "RxBin": rxBIN,
      "RxPcn": rxPCN,
      "GroupId": discountGroupId,
      "CardHolderId": discountGroupId
    }

    return (
      <>
        <div className={`
            ${isFrame ? 'c-coupon-detail__card' : 'c-coupon-detail__card card-padding'}
          `}>
          {!isFrame && <div className='c-coupon-detail__logo'>
            <img src='/images/ddn-logo.png' alt='Discount Drug Network' />
          </div>}
          <div className={`
            ${isFrame ? '' : 'c-coupon-detail__logo__iframe'}
          `}></div>
          <div className={`
            c-coupon-detail__drug
            ${isFrame ? 'c-coupon-detail__drug--iframe' : ''}
          `}>
            <div>Your estimated price</div>
            <div className='c-coupon-detail__price'>
              ${price && numberWithCommas(price.toFixed(2))}
            </div>
            <div>at {pharmacyName}</div>
            <div className='c-coupon-detail__drugname'>
              {genericName}
            </div>
            <div className='c-coupon-detail__filter'>
              <span>{strength}</span>
              <span>{quantity} {form}</span>
            </div>
            <div className='c-coupon-detail__reminder'>
              <b>Reminder:</b> This discount card can be used for any prescriptions
              for you and your family. Discount program. Not insurance.
            </div>
            <div className={`
            ${isFrame ? 'c-coupon-detail__ddn' : 'c-coupon-detail__ddn no-iframe'}
          `}>
              Powered by Discount Drug Network
            </div>
          </div>
          <div className={`
            c-coupon-detail__discount
            ${isFrame ? 'c-coupon-detail__discount--iframe' : ''}
          `}>
            <div className='c-coupon-detail__pharmacy-use'>Pharmacy Use</div>
            <div className='c-coupon-detail__discount-numbers'>
              {discountInfoRows.map(({ label, value }) => (
                <div key={label}>
                  <div>{label}</div>
                  <div className="c-coupon-detail__discount-numbers__value">{value}</div>
                </div>
              ))}
            </div>
            <div className='c-coupon-detail__attention'>
              <b>Attention Pharmacist:</b> This card is active and entitles the card holder
              to all prescription drug benefits associated with the
              BIN and Group Numbers (per state and federal laws).
            </div>
            <div className='c-coupon-detail__contact'>
              Pharmacist &amp; Customer Support:<br />877-537-5537
            </div>
          </div>
        </div>
        <CouponSend isFrame={isFrame} messageBody={messageBody} emailBody={emailBody} />
      </>
    )
  }
  if (!drugData) return null;

  return (
    <section className='c-coupon-detail'>
      <div className='c-coupon-detail__back' onClick={handleBackToPrices}>
        <ArrowLeftOutlined /> <span>Back to all prices</span>
      </div>
      <div className='c-coupon-detail__guide'>
        Present this discount card to your pharmacist
      </div>
      {renderDetail()}
    </section>
  )
}

export default CouponDetail
