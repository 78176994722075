import React from 'react'
import { useSelector } from 'react-redux'
import { selectIsIframe, selectIframeContainerBgColor, selectDDNContainerBgColor } from 'features/auth/redux/authSelector'

export const Screen = ({ children }) => {
  return (
    <section className='g-screen'>
      {children}
    </section>
  )
}

export const Container = ({ children, circle, additionalClassName = '', ..._props }) => {
  const isIframe = useSelector(selectIsIframe)
  const iframeContainerBgColor = useSelector(selectIframeContainerBgColor)
  const DDNContainerBgColor = useSelector(selectDDNContainerBgColor)
  const iframeContainerBgColorStyle = { style: { backgroundColor: iframeContainerBgColor } }
  const ddnContainerBgColorStyle = { style: { backgroundColor: DDNContainerBgColor } }
  return (
    <section className={`
      ${isIframe ? 'g-container-iframe' : 'g-container' }
      ${circle ? 'g-container--circle' : ' '}
      ${additionalClassName}
    `} {..._props}
    {...isIframe ? ddnContainerBgColorStyle : iframeContainerBgColorStyle }
    >
      {children}
    </section>
  )
}

export const Content = ({ children }) => {
  return (
    <section id='ddn-search-content' className='g-content'>
      {children}
    </section>
  )
}

export const Title = ({ children, className = '' }) => {
  return (
    <div className={`g-content__title ${className}`}>
      {children}
    </div>
  )
}
