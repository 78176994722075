import pkg from '../../../package.json';
import * as Sentry from '@sentry/react';

const env = process.env.REACT_APP_ENVIRONMENT;

if (env && env !== 'development') {
  Sentry.init({
    dsn:
      'https://3149e3fba8164b51aa97cdfe3f52ade5@o416864.ingest.sentry.io/5608879',
    release: `GOFE@${pkg.version}`,
    environment: env,
    // https://docs.sentry.io/platforms/javascript/performance/sampling/
    tracesSampleRate: 0.2,
    autoSessionTracking: false,
  });
}
