export const selectToken = state => state.auth.token
export const selectExpiresAt = state => state.auth.expiresAt
export const selectIsIframe = state => state.auth.iframe
export const selectIframeGroupId = state => {
  const { auth: { iframe, groupId } } = state
  return iframe ? groupId : null
}
export const selectIframeContainerBgColor = state => state.auth.containerBgColor
export const selectDDNContainerBgColor = state => state.auth.ddnContainerBgColor
export const selectIframeSearchBarBgColor = state => state.auth.resultSearchBarBgColor
