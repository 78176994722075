import 'bootstrap/sentry';
import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { ConnectedRouter } from 'connected-react-router';
import { PersistGate } from 'redux-persist/integration/react';
import store, { persistor, history } from './bootstrap/redux';
import { ErrorBoundary } from '@sentry/react';

import 'shared/assets/scss/base.scss';

import App from './App';

const NODE: string = 'root';

ReactDOM.render(
  <ErrorBoundary fallback={'An error has occurred'}>
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <ConnectedRouter history={history}>
          <App />
        </ConnectedRouter>
      </PersistGate>
    </Provider>
  </ErrorBoundary>,
  document.getElementById(NODE),
);
