import { Form, Row, Col } from 'antd'
import React, { useEffect } from 'react'
import { Container, FormInput, DdnSelect, PrimaryButton, FormAreaInput, SEO } from 'shared/components'
import FaqItem from 'shared/components/FaqItem'
import { contactFaqsData } from 'shared/constants/faqs'

const rowProps = {
  type: 'flex',
  gutter: 40
}

const colMdProps = {
  xs: 24,
  md: 12
}

const hideForm = true

const Contact = () => {
  const [form] = Form.useForm()

  useEffect(() => {
    window.scrollTo({ top: 0 })
  }, [])

  const handleSendText = (values) => {
    console.log(values)
  }

  const checkMessageLength = (rule, value, callback) => {
    if (value.length >= 1000) {
      callback('Message exceeds length limit.')
    } else {
      callback()
    }
  }

  const renderContactForm = () => {
    if (hideForm) {
      return (
        <div className='c-paragraph--lg c-paragraph__link c-contact__message-link'>
          <a href="mailto:support@discountdrugnetwork.com">Send us a message</a>
        </div>
      )
    }
    return (
      <div className='c-contact__message-form'>
        <div className='c-heading c-heading--sm'>
          Send us a message
        </div>
        <Form
          form={form}
          onFinish={handleSendText}
        >
          <Form.Item name='name' rules={[{ required: true, message: 'Please enter name' }]}>
            <FormInput label='FIRST AND LAST NAME' placeholder='Ex. John Dough' />
          </Form.Item>
          <Form.Item name='email' rules={[{ required: true, message: 'Please enter email' }]}>
            <FormInput label='EMAIL ADDRESS' placeholder='Ex. john.dough@email.com' type='email' />
          </Form.Item>
          <Form.Item
            name='reason'
            rules={[{ required: true, message: 'Please select reason' }]}
          >
            <DdnSelect
              full
              label='REASON FOR CONTACT'
              placeholder='Select'
              field='value'
              id='text'
              items={[
                { value: '1', text: 'Custom Company Option' },
                { value: '2', text: 'Apply to be an affiliate' },
                { value: '3', text: 'Card Issues' },
                { value: '4', text: 'Other' },
              ]}
            />
          </Form.Item>
          <Form.Item name='message' rules={[
            { required: true, message: 'Please enter message' },
            { validator: checkMessageLength }
          ]}>
            <FormAreaInput
              label='MESSAGE'
              placeholder='How can we help you?'
              additionalClassName='c-contact__textarea'
            />
          </Form.Item>
          <PrimaryButton className='c-contact__submit' size='md' type='submit'>
            Send
          </PrimaryButton>
        </Form>
      </div>
    )
  }

  return (
    <div className='c-contact'>
      <SEO title='Contact - Discount Drug Network' description='Contact the Discount Drug Network' />
      <div className='c-heading c-heading--center c-contact__heading'>
        Get in Touch
      </div>
      <div className='c-paragraph--lg c-paragraph--center'>
        Check out our <a className='c-paragraph__link' href='#faqs'>FAQs</a> below.
        If you still need help, call or send us a message.
      </div>
      <Row {...rowProps}>
        <Col {...colMdProps}>
          <div className='c-contact__contact-info'>
            <div className='c-heading c-heading--sm'>
              Call customer or pharmacy support
            </div>
            <p className='c-paragraph c-paragraph-nomargin'>
              1-877-537-5537
            </p>
            <p className='c-paragraph c-paragraph-nomargin'>
              Daily: 9 AM to 8 PM ET
            </p>
            <p className='c-paragraph c-paragraph-nomargin'>
              Sat: 10 AM to 3 PM ET
            </p>
            <div className='c-contact__divide' />
            <div className='c-heading c-heading--sm'>
              Mailing address
            </div>
            <p className='c-paragraph c-paragraph-nomargin'>
              10 Elder Ave
            </p>
            <p className='c-paragraph c-paragraph-nomargin'>
              Attn: DDN
            </p>
            <p className='c-paragraph c-paragraph-nomargin'>
              Kingston, MA&nbsp;&nbsp;02364
            </p>
          </div>
        </Col>
        <Col {...colMdProps}>
          {renderContactForm()}
        </Col>
      </Row>
      <Container id='faqs'>
        <div className='c-heading c-heading--center'>FAQs</div>
        {contactFaqsData.map(d => <FaqItem key={d.question} {...d} />)}
      </Container>
    </div>
  )
}

export default Contact
