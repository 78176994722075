import axios from 'axios'
import { login, refreshAccess } from 'features/auth/redux/authActions'
import store from '../redux'

const getAccessToken = async () => {
  const { token, refreshAt, expiresAt } = store.getState().auth
  const timestamp = Math.floor(Date.now() / 1000)
  if (!token || (expiresAt && expiresAt <= timestamp)) {
    await store.dispatch(login())
  } else if (refreshAt && refreshAt <= timestamp) {
    await store.dispatch(refreshAccess())
  }
  const { token: accessToken } = store.getState().auth
  return accessToken
}

const API_HOST = process.env.REACT_APP_API_HOST

const options = process.env.NODE_ENV === 'development'
  ? {} : { baseURL: API_HOST }
let axiosInstance = axios.create(options)

axiosInstance.interceptors.response.use(
  response => response.data,
  error => console.error(error)
)

export const request = async (config) => {
  const { isPublic, ...options } = config
  const token = !isPublic ? await getAccessToken() : null
  const headers = {
    'Content-Type': 'application/json',
    'cache-control': 'no-cache',
    ...(options.headers || {})
  }
  if (token) {
    headers.Authorization = `Bearer ${token}`
  }
  return axiosInstance.request({ ...options, headers })
}

export function get(url, config = {}) {
  return request({ method: 'get', url, ...config })
}

export function post(url, data, config = {}) {
  return request({ method: 'post', url, data, ...config })
}

export function patch(url, data, config = {}) {
  return request({ method: 'patch', url, data, ...config })
}

export function del(url, config = {}) {
  return request({ method: 'delete', url, ...config })
}

export default axiosInstance
