import React, { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { setIframeState } from 'features/auth/redux/authActions'
import { SEO } from 'shared/components'

const DEMO_URL = process.env.REACT_APP_DEMO_IFRAME_URL

const Demo = () => {
  const dispatch = useDispatch()

  useEffect(() => {
    const iframe = document.getElementById('ddn-demo-iframe')
    const handleResize = (e) => {
      if (typeof e.data === 'number') {
        iframe.height = e.data + 'px'
      }
    }
    window.addEventListener('message', handleResize)
    return () => {
      window.removeEventListener('message', handleResize)
    }
  }, [])

  useEffect(() => {
    dispatch(setIframeState(true))
  }, [dispatch])
 
  const onLoad = (e) => {
    const frame = e.target
    frame.contentWindow.postMessage({
      iframe: true,
      groupId: '225RXA',
      containerBgColor: 'transparent'
    }, '*')
  }
 
  return (
    <div className='c-demo'>
      <SEO
        title='DDN Plus'
        description='Embeded demo of Discount Drug Network search tool'
        meta={{ name: 'robots', content: 'noindex' }}
      />
      <iframe
        id='ddn-demo-iframe'
        className='c-demo-iframe'
        src={DEMO_URL}
        scrolling='no'
        title='Discount Drug Network Search Iframe'
        onLoad={onLoad}
      />
    </div>
  )
}

export default Demo
