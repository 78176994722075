import { stringify, parse } from 'qs'

export const queryStringify = (filters) => 
  stringify(filters, { encode: true, addQueryPrefix: true })

export const parseQueryString = (queryStr) =>
  parse(queryStr, { ignoreQueryPrefix: true })

export const clearQueryIdentifier = (query) => {
  const numPharm = 25
  const brandIndicator = query.brandIndicator || "G"
  const { iframe, pharmacy, drugName, gsn, ..._query } = query
  if (gsn) {
    return { gsn, numPharm, brandIndicator, ..._query }
  } else {
    return { drugName, numPharm, brandIndicator, ..._query }
  }
}
