import { MobileOutlined, MailOutlined, PrinterOutlined } from '@ant-design/icons'
import React, { useState } from 'react'
import { useSelector } from 'react-redux'
import { PrimaryButton } from 'shared/components'
import {
  selectLoadingCoupon
} from 'features/search/redux/searchSelector'
import SendModal from './SendModal'
import TextSendForm from './TextSendForm'
import EmailSendForm from './EmailSendForm'
import { print } from 'utils'

const CouponSend = ({ messageBody, emailBody }) => {
  const [textVisible, setTextVisible] = useState(false)
  const [emailVisible, setEmailVisible] = useState(false)
  const DiscountCardUrl = window.location.href;
  const isLoadingCoupon = useSelector(selectLoadingCoupon)

  if (isLoadingCoupon) {
    return null
  }

  return (
    <section className='c-coupon-send'>
      <SendModal
        visible={textVisible}
        toggleModal={setTextVisible}
      >
        <TextSendForm
          toggleModal={setTextVisible}
          messageBody={messageBody}
        />
      </SendModal>
      <SendModal
        visible={emailVisible}
        toggleModal={setEmailVisible}
      >
        <EmailSendForm
          toggleModal={setEmailVisible}
          DiscountCardUrl={DiscountCardUrl}
          emailBody={emailBody}
        />
      </SendModal>
      <div className='c-coupon-send__buttons'>
        <PrimaryButton
          onClick={() => setTextVisible(true)}
        >
          <MobileOutlined /> Text Card
        </PrimaryButton>
        <PrimaryButton
          onClick={() => setEmailVisible(true)}
        >
          <MailOutlined /> Email Card
        </PrimaryButton>


        <PrimaryButton
          onClick={print}
        >
          <PrinterOutlined /> Print Card
        </PrimaryButton>
      </div>
    </section>
  )
}

export default CouponSend
