import React from 'react'
import { Container } from 'shared/components'

export default ({ stepNumber, title, description }) => {
  return (
    <div className='c-search-step'>
      <Container circle additionalClassName='c-search-step__number'>
        {stepNumber}
      </Container>
      <div className='c-search-step__title'>
        {title}
      </div>
      <div className='c-search-step__desc'>
        {description.map((desc, i) => (<p key={i}>{desc}</p>))}
      </div>
    </div>
  )
}
