import React from "react"
import { Helmet } from "react-helmet"

export const SEO = ({
    title = 'Discount Drug Network',
    description = '',
    meta = {},
    lang = 'en',
    type = 'website',
    author = '',
    summary = '',
    url = '',
    image = '',
    fbAppId = '',
  }) => (
    <Helmet
      title={title}
      htmlAttributes={{ lang }}
      meta={[
        {
            name: "description",
            content: description,
        },
        {
            property: "og:title",
            content: title,
        },
        {
            property: "og:description",
            content: description,
        },
        {
            property: "og:type",
            content: type,
        },
        {
          property: "og:url",
          content: url
        },
        {
          property: "og:image",
          content: image
        },
        {
          property: "fb:app_id",
          content: fbAppId
        },
        {
            name: "twitter:creator",
            content: author,
        },
        {
            name: "twitter:title",
            content: title,
        },
        {
            name: "twitter:description",
            content: description,
        },
        {
          name: "twitter:card",
          content: summary,
        },
        {
          property: "twitter:image",
          content: image
        }
      ].concat(meta)}
    />
  )
