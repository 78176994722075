import React from 'react'
import Step from './Step';

const steps = [
  {
    stepNumber: '1',
    title: 'Search your drug name',
    description: ['Search our network of', 'pharmacies for your prescription']
  },
  {
    stepNumber: '2',
    title: 'Select the best price',
    description: ['Select the best price at the most', 'convenient location for you']
  },
  {
    stepNumber: '3',
    title: 'Start saving',
    description: ['Print, text or email the savings', 'information to show your pharmacist']
  },
]

export default () => {
  return (
    <>
      <section className='c-search-steps'>
        <div className='c-search-steps__title'>
          How to <span>save</span> on your prescriptions
        </div>
        <div className='c-search-steps__container'>
          {steps.map((step, i) => (
            <Step {...step} key={i} />
          ))}
        </div>
      </section>
      <section className='fsm'>
        <div className='container'>
          <h1>Frequently Searched Medications</h1>
          <div className='fsm__meds'>
            <div className='fsm__meds__med'>
              <a href="/get-discount?drugName=AMOXICILLIN">Amoxicillin</a>
            </div>
            <div className='fsm__meds__med'>
              <a href="/get-discount?drugName=GABAPENTIN">Gabapentin</a>
            </div>
            <div className='fsm__meds__med'>
              <a href="/get-discount?drugName=LISINOPRIL">Lisinopril</a>
            </div>
            <div className='fsm__meds__med'>
              <a href="/get-discount?drugName=SILDENAFIL%2CITRATE">Sildenafil Citrate</a>
            </div>
            <div className='fsm__meds__med'>
              <a href="/get-discount?drugName=ALBUTEROL%20SULFATE%20HFA">Albuterol Sulfate HFA</a>
            </div>
            <div className='fsm__meds__med'>
              <a href="/get-discount?drugName=HYDROCODONE">Hydrocodone</a>
            </div>
            <div className='fsm__meds__med'>
              <a href="/get-discount?drugName=PANTOPRAZOLE">Pantoprazole</a>
            </div>
            <div className='fsm__meds__med'>
              <a href="/get-discount?drugName=TADALAFIL">Tadalafil</a>
            </div>
            <div className='fsm__meds__med'>
              <a href="/get-discount?drugName=ATORVASTATIN">Atorvastatin</a>
            </div>
            <div className='fsm__meds__med'>
              <a href="/get-discount?drugName=IBUPROFEN">Ibuprofen</a>
            </div>
            <div className='fsm__meds__med'>
              <a href="/get-discount?drugName=PHENTERMINE%20HCL">Phentermine HCL</a>
            </div>
            <div className='fsm__meds__med'>
              <a href="/get-discount?drugName=VITAMIN%20D2">Vitamin D2</a>
            </div>
            <div className='fsm__meds__med'>
              <a href="/get-discount?drugName=BENZONATATE">Benzonatate</a>
            </div>
            <div className='fsm__meds__med'>
              <a href="/get-discount?drugName=LIPITOR">Lipitor</a>
            </div>
            <div className='fsm__meds__med'>
              <a href="/get-discount?drugName=PREDNISONE">Prednisone</a>
            </div>
            <div className='fsm__meds__med'>
              <a href="/get-discount?drugName=XANAX">Xanax</a>
            </div>
          </div>
        </div>
      </section>
    </>
  )
}
