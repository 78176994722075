import { get } from 'bootstrap/axios'
import { queryStringify } from 'utils/tools'

export const findDrugs = async (filter) => {
  const query = queryStringify(filter) //?drugName=AMOXICILLIN&zip=14580
  const url = `/gateway/Drug/FindDrugs${query}`
  return await get(url)
}

export const autoCompleteDrugName = async (prefixText) => {
  const query = queryStringify({ prefixText })
  const url = `/gateway/Drug/Autocomplete${query}`
  return await get(url)
}

export const getDiscountInfo = async () => {
  const url = `/gateway/Drug/DiscountInfo`
  return await get(url)
}
