import { createAction } from '@reduxjs/toolkit'
import * as authService from 'utils/api/auth'
import authTypes from './authTypes'

export const setIframeState = createAction(authTypes.SET_IFRAME_STATE)
export const setIframeGroupId = createAction(authTypes.SET_IFRAME_GROUP_ID)
export const setIframeContainerBgColor = createAction(authTypes.SET_IFRAME_CONTAINER_BG_COLOR)

const loginUserBegin = createAction(authTypes.LOGIN_BEGIN)
const loginUserSuccess = createAction(authTypes.LOGIN_SUCCESS)
const loginUserFail = createAction(authTypes.LOGIN_FAIL)

export const login = () => async (dispatch, getState) => {
  dispatch(loginUserBegin())
  try {
    const { auth: { groupId } } = getState()
    const res = await authService.authLogin(groupId)
    dispatch(loginUserSuccess(res))
  } catch (e) {
    dispatch(loginUserFail(e))
  }
}

const refreshTokenBegin = createAction(authTypes.REFRESH_TOKEN_BEGIN)
const refreshTokenFail = createAction(authTypes.REFRESH_TOKEN_FAIL)

export const refreshAccess = () => async (dispatch, getState) => {  
  dispatch(refreshTokenBegin())
  try {
    const { auth: { token, refreshToken } } = getState()
    const res = await authService.refreshToken(token, refreshToken)
    dispatch(loginUserSuccess(res))
  } catch (e) {
    dispatch(refreshTokenFail(e))
  }
}
