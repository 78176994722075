import { createStore, applyMiddleware, combineReducers } from 'redux'
import { persistStore, persistReducer } from 'redux-persist'
import { persistConfig } from './persist'
import { composeWithDevTools } from 'redux-devtools-extension/developmentOnly'
import optimist from 'redux-optimist'
import { reduxMiddleware } from './middleware'
import { connectRouter, routerMiddleware } from 'connected-react-router'
import { createBrowserHistory } from 'history'
import { createReduxEnhancer } from "@sentry/react"

import auth from 'features/auth/redux/authReducer'
import search from 'features/search/redux/searchReducer'

const sentryReduxEnhancer = createReduxEnhancer({
  // Optionally pass options listed below
});

const createRootReducer = history => (
  optimist(
    combineReducers({
      router: connectRouter(history),
      auth: persistReducer(persistConfig, auth),
      search
    })
  )
)

export const history = createBrowserHistory()
const rootReducer = createRootReducer(history)

const configureStore = (preloadedState = {}) => {
  const store = createStore(
    rootReducer,
    preloadedState,
    composeWithDevTools(
      applyMiddleware(
        ...reduxMiddleware,
        routerMiddleware(history)
      ),
      sentryReduxEnhancer
    )
  )
  return store
}

const store = configureStore()

export const persistor = persistStore(store)

export default store
