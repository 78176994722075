import React from 'react'
import ToggleIcon from './ToggleIcon'

export const ToggleBtn = ({ show, disabled = false, hide = false, label, onClick, className = '' }) => {
  if (hide) return null

  return (
    <button
      className={`c-toggle-show ${className}`}
      onClick={onClick}
      disabled={disabled}
    >
      <span>{label}</span>
      <ToggleIcon show={show} />
    </button>
  )
}
