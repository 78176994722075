import React from 'react'

export const Loading = ({ iconSize = 200, cover = false, className = '' }) => {
  const offset = 38
  const blockClass = cover ? `c-loading c-loading--cover ${className}` : `c-loading ${className}`
  const iconClass = cover ? 'c-loading__icon c-loading__icon--cover' : 'c-loading__icon'
  return (
    <div className={blockClass} >
      <svg
        xmlns='http://www.w3.org/2000/svg'
        version='1.1'
        viewBox='0 0 200 200'
        width={iconSize}
        height={iconSize}
        className={iconClass}
      >
        <polygon
          points={`${156 + offset},100 ${0 + offset},190 ${0 + offset},10`}
          opacity='1'
          stroke='#e7131a'
          strokeWidth='8'
          fillOpacity='0'
        />
      </svg>
    </div>
  )
}

export const ListLoading = ({ loading = true }) => {
  return loading ? (
    <div className='c-listloading__container'>
      <div className='c-listloading__spinner'>
        <div className='c-listloading__spinner-wrapper'>
          <div />
          <div />
          <div />
        </div>
      </div>
    </div>
  ) : null
}
