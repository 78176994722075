import React from 'react'
import { MinusCircleOutlined, PlusCircleOutlined } from '@ant-design/icons'

export default ({ show, fontSize = '17px' }) => {
  return (
    show
      ? <MinusCircleOutlined style={{ fontSize }} />
      : <PlusCircleOutlined style={{ fontSize }} />
  )
}
