import React from 'react'
import { ResultSearch, SearchResults } from 'features/search/components'
import { SEO } from 'shared/components'

const GetDiscount = () => {
  return (
    <div className='c-search'>
      <SEO title='Search Results - Discount Drug Network' description='Search for your prescription to receive your savings coupon' />
      <ResultSearch />
      <SearchResults />
    </div>
  )
}

export default GetDiscount
