import { 
  getActionTypes,
  getMultiActionTypes
} from 'bootstrap/redux/action'

const prefix = 'AUTH'

// CLEAR_TOKEN: AUTH/CLEAR_TOKEN
const unitTypes = getActionTypes([
  'CLEAR_TOKEN',
  'RESET_ROLE', // Set Role for Permission Test
  'SET_USER_SERVICE_UNAVAILABLE',
  'SET_IFRAME_STATE',
  'SET_IFRAME_GROUP_ID',
  'SET_IFRAME_CONTAINER_BG_COLOR',
], prefix)

/** 
  * REFRESH_TOKEN: AUTH/REFRESH_TOKEN
  * REFRESH_TOKEN_BEGIN: AUTH/REFRESH_TOKEN_BEGIN
  * REFRESH_TOKEN_SUCCESS: AUTH/REFRESH_TOKEN_SUCCESS
  * REFRESH_TOKEN_FAIL: AUTH/REFRESH_TOKEN_FAIL
  **/
const multiTypes = getMultiActionTypes([
  'LOGIN',
  'LOGOUT',
  'REFRESH_TOKEN',
  'UPDATE_PROFILE'
], prefix)

export default {
  ...unitTypes,
  ...multiTypes
}
