import React, { useState, useEffect } from 'react';
import Media from 'react-media';
import { QUERY_MD } from 'shared/constants';
import { ToggleBtn, PrimaryButton } from 'shared/components';
import { setFrameHeight } from 'utils/iframe';
import { numberWithCommas } from 'utils';

const TopWrap = ({ isMd, children }) =>
  isMd ? <React.Fragment>{children}</React.Fragment> : <div>{children}</div>;

const Location = ({ loc, distance, space }) => {
  if (space)
    return (
      <div className="c-results__location">
        <span>{loc}</span>
        <span>&nbsp;({distance} mi)</span>
      </div>
    );
  return (
    <div>
      {loc} ({distance}mi)
    </div>
  );
};

const ResultItem = ({ results, handleGetDiscount }) => {
  const [initialRender, setInitialRender] = useState(true);
  const [showLocations, setShowLocations] = useState(false);

  const { pharmacyName, price, ucPrice } = results[0];
  const priceTooHigh = price > 999999;
  const locations = results.map(({ address, city, state, distance, zip }) => {
    const truncatedZip = zip.substring(0, 5);

    return {
      loc: `${address}, ${city}, ${state} ${truncatedZip}`,
      distance: distance && distance.toFixed(2),
    };
  });

  const multipleResults = locations.length > 1;

  useEffect(() => {
    if (!initialRender) {
      setFrameHeight();
    }
    return () => setInitialRender(false);
  }, [showLocations, initialRender]);

  const handleToggleLocations = () => {
    setShowLocations((visible) => !visible);
  };

  const renderToggleBtn = (hide = false) => (
    <ToggleBtn
      label={`${showLocations ? 'Hide' : 'More'} Locations`}
      onClick={handleToggleLocations}
      show={showLocations}
      hide={!multipleResults || hide}
      className={'c-results__toggle-btn'}
    />
  );

  const renderLocations = (visible, isMd, renderFirstLocationOnly) => {
    if (visible && !renderFirstLocationOnly) {
      return (
        <div
          className={`
          c-results__locations
          ${multipleResults ? 'c-results__locations--multi' : ''}
        `}>
          {locations.map((l, i) => {
            // don't repeat first address in expandable address list on locations with multipleResults
            if (i !== 0) {
              return (
                <Location key={l.loc} {...l} space={multipleResults && isMd} />
              )
            }
            // returning null when i === 0
            return null;
          })}
          {/* {renderToggleBtn(!multipleResults)} */}
        </div>
      );
    } else if (renderFirstLocationOnly) {
      return (
        <div className="c-results__locations">
          <Location key={locations[0].loc} {...locations[0]} />
        </div>
      );
    }
    return null;
  };

  function priceSavings(price, ucPrice) {
    return ((ucPrice - price) / ucPrice) * 100;
  }

  if (priceTooHigh) {
    return null;
  }

  return (
    <Media queries={{ isMd: QUERY_MD }}>
      {({ isMd }) => (
        priceTooHigh ? null : (
          <div className={`
      c-results__item
      ${!isMd ? 'c-results__item--sm' : ''}
    `}>
            <div
              className={`
          c-results__top-wrap
          ${!isMd ? 'c-results__top-wrap--sm' : ''}
        `}>
              <TopWrap isMd={isMd} className="c-results__pharmacy">
                <div className="c-results__pharmacy-wrap">
                  <div className="c-results__pharmacyname">{pharmacyName}</div>
                  {renderLocations(true, isMd, true)}
                  {renderToggleBtn(
                    !isMd, isMd && multipleResults && showLocations,
                  )}
                </div>
                <div className="c-results__prices desktop-only">
                  <div className="c-results__before-price">
                    {priceSavings(price, ucPrice).toFixed(0) !== '0' && priceSavings(price, ucPrice) < 98 && (
                      <>
                        <p>${ucPrice && numberWithCommas(ucPrice.toFixed(2))}</p>
                        <p>SAVE {priceSavings(price, ucPrice).toFixed(0)}%</p>
                      </>
                    )}
                  </div>
                  <div className="c-results__price">
                    <p>${price && numberWithCommas(price.toFixed(2))}</p>
                  </div>
                </div>
              </TopWrap>
              <TopWrap isMd={isMd}>
                <div className="c-results__discount-btn-wrap desktop-only">
                  <PrimaryButton
                    onClick={() => handleGetDiscount(pharmacyName)}
                    className={'c-results__discount-btn'}>
                    Get Discount
                  </PrimaryButton>
                </div>
                <div className="c-results__discount-btn-wrap mobile-only">
                  <PrimaryButton
                    onClick={() => handleGetDiscount(pharmacyName)}
                    className={
                      priceSavings(price, ucPrice).toFixed(0) !== '0'
                        ? 'c-results__discount-btn c-results__discount-btn--savings'
                        : 'c-results__discount-btn'
                    }>
                    {priceSavings(price, ucPrice).toFixed(0) !== '0' && priceSavings(price, ucPrice) < 98 && (
                      <>
                        <span className="c-results__discount-btn-wrap__before">
                          <span className="strikethrough">${ucPrice}</span> SAVE{' '}
                          {priceSavings(price, ucPrice).toFixed(0)}%
                        </span>
                      </>
                    )}
                    {/* {priceSavings(price, ucPrice).toFixed(0) == '0' && (
                  <>
                    <span className="c-results__discount-btn-wrap__before">
                      AS LOW AS
                    </span>
                  </>
                )} */}
                    ${numberWithCommas(price.toFixed(2))}
                  </PrimaryButton>
                </div>
              </TopWrap>
            </div>
            {renderToggleBtn(isMd)}
            {renderLocations(multipleResults && showLocations, isMd)}
          </div>
        )
      )
      }
    </Media>
  );
};

export default ResultItem;
