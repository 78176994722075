import React from 'react'

export default ({ width = 14, fill = '#00BCA5' }) => (
  <svg width={`${width}px`} height={`${width}px`} viewBox='0 0 28 28' version="1.1" xmlns="http://www.w3.org/2000/svg">
    <g id="Symbols" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g id="Icons" transform="translate(-932.000000, -203.000000)" fill={fill}>
        <g id="Icons/info" transform="translate(930.000000, 201.000000)">
          <path d="M16,2 C23.73125,2 30,8.26875 30,16 C30,23.73125 23.73125,30 16,30 C8.26875,30 2,23.73125 2,16 C2,8.26875 8.26875,2 16,2 Z M16,4.375 C9.58125,4.375 4.375,9.58125 4.375,16 C4.375,22.41875 9.58125,27.625 16,27.625 C22.41875,27.625 27.625,22.41875 27.625,16 C27.625,9.58125 22.41875,4.375 16,4.375 Z M16.75,14 C16.8875,14 17,14.1125 17,14.25 L17,14.25 L17,22.75 C17,22.8875 16.8875,23 16.75,23 L16.75,23 L15.25,23 C15.1125,23 15,22.8875 15,22.75 L15,22.75 L15,14.25 C15,14.1125 15.1125,14 15.25,14 L15.25,14 Z M16,9 C16.8284271,9 17.5,9.67157288 17.5,10.5 C17.5,11.3284271 16.8284271,12 16,12 C15.1715729,12 14.5,11.3284271 14.5,10.5 C14.5,9.67157288 15.1715729,9 16,9 Z" id="Combined-Shape"></path>
        </g>
      </g>
    </g>
  </svg>
)
