import React, { useEffect } from 'react'
import { SEO } from 'shared/components'

export default () => {
  useEffect(() => {
    window.scrollTo({ top: 0 })
  }, [])

  return (
    <div className='c-privacy-policy'>
      <SEO title='Privacy Policy & Terms of Use - Discount Drug Network' description='Discount Drug Network presents the standards of use of website and associated discount programs.' />
      <div className='c-privacy-policy__heading c-heading c-heading--center'>
        Privacy Policy & Terms of Use
      </div>
      <div className='c-paragraph--sm'>
        This Agreement sets forth the standards of use of the Discount Drug Network (“DDN”) website and associated prescription/medical discount programs. By using our website, programs, products, services or discounts (collectively, “Services”), you agree to these terms and conditions. If you do not agree to the terms and conditions of this agreement, you are free to end your voluntary arrangement with us at any time.  We reserve the right, at any time, to modify, alter, or update the terms and conditions of this agreement without prior notice. Modifications shall become effective immediately upon being posted at on our website. Your continued use of the Services after amendments are posted constitutes an acknowledgement and acceptance of the Agreement and its modifications. Except as provided in this paragraph, this Agreement may not be amended.
      </div>
      <div className='c-heading c-heading--xs u-mt24'>
        1. Privacy Statement
      </div>
      <p className='c-paragraph--sm u-mt12'>
        At DDN we are committed to ensuring your online privacy, and this guides every decision we make about how, where, and when to collect your personally identifiable information when rendering our Services. Our site meets stringent security and privacy standards for both e-commerce and personal information. We have provided this notice to describe the online information collection and use practices at DDN. This document describes the choices you can make about the way your information is collected and used. Any changes in our Privacy Policy will appear on this page so that you are aware of the data we collect and how we use it.
      </p>
      <p className='c-paragraph--sm u-mt12'>
        DDN is owned and operated by Discount Drug Network, LLC. Your personally identifiable information is information that can be used to contact or identify you. Your name, home address, telephone number and email address are examples of personally identifiable information. Currently, we ask for your name and email so that we can provide the Services to you.  We also have provided you with the option to provide your phone number and mailing address (if you want a physical card mailed to you).  In addition, we will, from time to time, use this personal information to provide you with certain news, offers and information relating to DDN.  If you wish to opt out of such communications at any time, please contact us at info@discountdrugnetwork.com.
      </p>
      <div className='c-heading c-heading--xs u-mt24'>
        2. Information Use
      </div>
      <p className='c-paragraph--sm u-mt12'>
        Information you provide through this website is used in order to provide the Services.  For example, we may contact you with information about services, to send you email alerts or to send you newsletters. From time to time DDN requests information from users via surveys which are voluntary, and information collected as part of these surveys is used in compliance with this privacy policy.  Individuals have the ability to opt out of these communications at any time. In the event that DDN or some of our assets are sold or transferred or used as security or to the extent we engage in business negotiations with our business partners, the information collected on this site may be transferred or shared with third parties as part of that transaction or negotiation. We may also provide information or provide access to information to any of our affiliated businesses or to our business partners or fulfillment houses.
      </p>
      <p className='c-paragraph--sm u-mt12'>
        If we are requested by law enforcement officials or judicial authorities to provide information on individuals, we may, without your consent, provide such information. In matters involving claims of personal or public safety or in litigation where the data is pertinent, we may use or disclose personal information without your consent or court process.
      </p>
      <p className='c-paragraph--sm u-mt12'>
        We may use information you submit to investigate security breaches or otherwise cooperate with authorities pursuant to a legal matter. We may also remove personally identifiable information and use remaining data for historical, statistical or business planning purposes.
      </p>
      <div className='c-heading c-heading--xs u-mt24'>
        3. Use of Information by Third Parties
      </div>
      <p className='c-paragraph--sm u-mt12'>
        In order to provide you with content and services that match your interests, DDN may have or develop relationships with third party companies to provide technology, services and/or content under DDN branding.
      </p>
      <p className='c-paragraph--sm u-mt12'>
        Some of the services we offer through relationships with third parties may require registration or subscription to make them function properly. You are under no obligation to provide your information to these third parties. You may choose not to share your information with such parties by not using that service or viewing that content. When you register or subscribe to use one of these services, the information you provide may be collected and saved by the service provider. These service providers may also use cookies on their sites. We are unable to control their use of cookies or how they manage the information they gather from these cookies. You should make every effort to read the individual privacy policies of these service providers, and make your own informed decision about whether or not to continue to share your information with them based upon their privacy practices.
      </p>
      <div className='c-heading c-heading--xs u-mt24'>
        4. Access Information and Passwords
      </div>
      <p className='c-paragraph--sm u-mt12'>
        If you use this site, you are responsible for maintaining the confidentiality of your access information and password and for restricting access to your computer, and you agree to accept responsibility for all activities that occur under your password.
      </p>
      <div className='c-heading c-heading--xs u-mt24'>
        5. Disclaimer of Warranties
      </div>
      <p className='c-paragraph--sm u-mt12'>
        The site is provided by DDN on an “as is” and on an “as available” basis. To the fullest extent permitted by applicable law, DDN makes no representations or warranties of any kind, express or implied, regarding the use or the results of this web site in terms of its correctness, accuracy, reliability, or otherwise. DDN shall have no liability for any interruptions in the use of this website. DDN disclaims all warranties with regard to the information provided, including the implied warranties of merchantability and fitness for a particular purpose, and non-infringement.
      </p>
      <p className='c-paragraph--sm u-mt12'>
        In addition, the Services provided by DDN hereunder are not intended to be medical advice of any sort or, in any manner, create a provider-patient relationship with DDN.  By using the Services, you explicitly acknowledge and agree that DDN is no way responsible for any medical decisions made by you or your physician and that DDN is not advising you to choose any certain medication or course of treatment over another.  DDN is simply providing pricing information to you without making any sort of analysis or evaluation of your particular medical needs.  As always, any comments, questions or concerns regarding a particular course of treatment or medication should be directed at your medical provider.
      </p>
      <div className='c-heading c-heading--xs u-mt24'>
        6. Limitation of Liability
      </div>
      <p className='c-paragraph--sm u-mt12'>
        DDN SHALL NOT be liable for any damages whatsoever, and in particular DDN shall not be liable for any special, indirect, consequential, or incidental damages, or damages for lost profits, loss of revenue, or loss of use, arising out of or related to this web site or the information contained in it, whether such damages arise in contract, negligence, tort, under statute, in equity, at law, or otherwise, even if DDN has been advised of the possibility of such damages.
      </p>
      <div className='c-heading c-heading--xs u-mt24'>
        7. Indemnification
      </div>
      <p className='c-paragraph--sm u-mt12'>
        You agree to indemnify and hold DDN, its parents, subsidiaries, affiliates, managers, members, officers and employees, harmless from any claim or demand, including reasonable attorneys’ fees and costs, made by any third party due to or arising out of the use of website, the violation of this Agreement, or infringement by you of any intellectual property or any other right of any person or entity.
      </p>
      <div className='c-heading c-heading--xs u-mt24'>
        8. Modifications and Interruption to Service
      </div>
      <p className='c-paragraph--sm u-mt12'>
        DDN reserves the right to modify or discontinue the website with or without notice. Website user acknowledges and accepts that DDN does not guarantee continuous, uninterrupted or secure access to our website and operation of our website may be interfered with or adversely affected by numerous factors or circumstances outside of our control.
      </p>
      <div className='c-heading c-heading--xs u-mt24'>
        9. Third-Party Sites
      </div>
      <p className='c-paragraph--sm u-mt12'>
        Our website may include links to other sites on the Internet that are owned and operated by other third parties. You acknowledge that we are not responsible for the availability of, or the content located on or through, any third-party site. You should contact the site administrator or webmaster for those third-party sites if you have any concerns regarding such links or the content located on such sites. Your use of those third-party sites is subject to the terms of use and privacy policies of each site, and we are not responsible therein. We encourage you to review said privacy policies of third-parties’ sites.
      </p>
      <div className='c-heading c-heading--xs u-mt24'>
        10. Governing Jurisdiction
      </div>
      <p className='c-paragraph--sm u-mt12'>
        Our website is operated and provided in the Commonwealth of Pennsylvania. As such, we are subject to the laws of the Commonwealth of Pennsylvania, and such laws will govern this Agreement, without giving effect to any choice of law rules. We make no representation that our website or other services are appropriate, legal or available for use in other locations. Accordingly, if you choose to access our site you agree to do so subject to the internal laws of the Commonwealth of Pennsylvania.
      </p>
      <div className='c-heading c-heading--xs u-mt24'>
        11. Copyright and Trademark Information
      </div>
      <p className='c-paragraph--sm u-mt12'>
        Any use of materials on the website, including reproduction modification, distribution, or replication, any form of data extraction or data mining, or other commercial exploitation of any kind, without prior written permission of an authorized officer of DDN is strictly prohibited. You agree that you will not use any robot, spider, or other automatic device, or manual process to monitor or copy our web pages or the content contained therein without prior written permission of an authorized officer of DDN.
      </p>
      <div className='c-heading c-heading--xs u-mt24'>
        12. Other Terms
      </div>
      <p className='c-paragraph--sm u-mt12'>
        If any provision of this Agreement shall be unlawful, void or unenforceable for any reason, the other provisions (and any partially-enforceable provision) shall not be affected thereby and shall remain valid and enforceable to the maximum possible extent. You agree that this Agreement and any other agreements referenced herein may be assigned by DDN, in our sole discretion, to a third party in the event of a merger or acquisition. This Agreement shall apply in addition to, and shall not be superseded by, any other written agreement between us in relation to your participation in our Services. Website user agrees that by accepting this Agreement, you are consenting to the use and disclosure of their personally identifiable information and other practices described herein.
      </p>
      <p className='c-paragraph--sm u-mt16'>
        <b>Effective Date: </b>
      </p>
      <p className='c-paragraph--sm'>
        This Agreement’s effective date is August 1, 2013.
      </p>
    </div>
  )
}
