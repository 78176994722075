import React from 'react'

const grey = '#98A0A9'
const blue = '#00BCA5'

const SortIcon = ({ width = 22, sort }) => {
  const upfill = sort === 'asc' ? blue : grey
  const downfill = sort === 'desc' ? blue : grey
  return (
    <svg width={`${width}px`} height={`${width * 1.5}px`} viewBox='0 0 22 30' version="1.1" xmlns="http://www.w3.org/2000/svg">
      <g id="Symbols" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g id="Icons" transform="translate(-1191.000000, -202.000000)">
          <g id="Icons/Sort" transform="translate(1186.000000, 201.000000)">
            <path fill={downfill} d="M26.2620821,17 L5.73791787,17 C5.12231793,17 4.77858193,17.6499735 5.15981641,18.0937055 L15.4218985,29.9932209 C15.7156366,30.3338321 16.2812385,30.3338321 16.5781015,29.9932209 L26.8401836,18.0937055 C27.2214181,17.6499735 26.8776821,17 26.2620821,17 Z" id="Path"></path>
            <path fill={upfill} d="M26.2620821,1 L5.73791787,1 C5.12231793,1 4.77858193,1.64997353 5.15981641,2.09370546 L15.4218985,13.9932209 C15.7156366,14.3338321 16.2812385,14.3338321 16.5781015,13.9932209 L26.8401836,2.09370546 C27.2214181,1.64997353 26.8776821,1 26.2620821,1 Z" id="Path-Copy" transform="translate(16.000000, 7.624340) rotate(-180.000000) translate(-16.000000, -7.624340) "></path>
          </g>
        </g>
      </g>
    </svg>
  )
}

export default React.memo(SortIcon)
