import { post } from 'bootstrap/axios'
import { getPlainPhoneNumber } from 'utils'

export const sendTextMessage = async (body) => {
  const url = '/gateway/sms/discount'
  const { phoneNumber, ...props } = body
  return await post(url, {
    phoneNumber: getPlainPhoneNumber(phoneNumber),
    ...props
  })
}

export const subscribeEmail = async ({ email, firstName, lastName, phoneNumber }) => {
  const url = `/gateway/mail/subscribe`
  return await post(url, {
    email,
    firstName,
    lastName,
    phone: phoneNumber,
  })
}

export const sentEmailMessage = async (body) => {
  try {
    const url = `/gateway/email/discount`
    return await post(url, body)
  } catch (err) {
    console.log(err);
    return err;
  }
}
