import React from 'react'
import { Modal } from 'antd'
import { maskStyle, bodyStyle } from 'shared/styles'

const SendModal = ({ children, visible, toggleModal }) => {
  return (
    <Modal
      visible={visible}
      closable
      footer={null}
      bodyStyle={bodyStyle}
      maskStyle={maskStyle}
      onCancel={() => toggleModal(false)}
    >
      <div className='c-coupon-modal'>
        {children}
      </div>
    </Modal>
  )
}

export default SendModal
