import React from 'react'
import { SearchIcon } from 'shared/components'
import Select, { components } from 'react-select'

const getStyles = (isDisabled) => ({
  control: () => ({}),
  valueContainer: () => ({}),
  input: (provided) => provided,
  menu: () => ({}),
  option: () => ({}),
  singleValue: (provided) => ({
    ...provided,
    color: isDisabled ? '#98A0A9' : '#FFFFFF'
  })
})

const DropdownIndicator = (props) => (
  <components.DropdownIndicator {...props}>
    <SearchIcon width={17.5} isDisabled={props.isDisabled} />
  </components.DropdownIndicator>
)

const getSearchOption = (checkStatus) => (props) => {
  const { data, isSelected, isFocused, isDisabled, selectOption } = props
  const { available } = data
  const disabled = isDisabled || !available
  const onSelect = () => {
    if (!checkStatus || !disabled) {
      selectOption(data)
    }
  }
  return <div className={`
    g-search__option
    ${disabled && checkStatus
      ? 'g-search__option--disabled'
      : isSelected || isFocused
        ? 'g-search__option--selected'
        : ''}
  `} onClick={onSelect}
  >
    <div
      {...data}
      checkStatus={checkStatus}
      selectPrefix='g-search'
    />
  </div>
}

export const SELECT_ACTIONS = {
  ON_INPUT_CHANGE: 'input-change',
  ON_CHANGE_CLEAR: 'clear',
}

export const GoSearch = React.memo(({
  placeholder, isDisabled, options, searching, checkStatus, value, inputValue, onChange, onSearch
}) => {
  const components = {
    DropdownIndicator,
    Option: getSearchOption(checkStatus)
  }
  const selectedOption = value ? options.find((o) => o.value === value) : null
  return (
    <Select
      hideSelectedOptions
      styles={getStyles(isDisabled)}
      className='g-search'
      classNamePrefix='g-search'
      isDisabled={isDisabled}
      isClearable
      isSearchable
      placeholder={placeholder}
      options={options}
      components={components}
      value={selectedOption}
      inputValue={inputValue}
      menuIsOpen={!!inputValue}
      onChange={onChange}
      onInputChange={onSearch}
      isLoading={searching}
    />
  )
})
