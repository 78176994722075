import React, {useState} from 'react'
import { Form, Row, Col, Checkbox } from 'antd'
import { FormInput, PrimaryButton } from 'shared/components'
import { sendTextMessage, subscribeEmail } from 'utils/api/message'
import toast from 'utils/toast'

export const rowProps = {
  type: 'flex',
  gutter: 8
}

export const colMdProps = {
  xs: 24,
  md: 12
}

export const SubscribeCheckbox = ({
  value, onChange, setValidate, ..._props
}) => {
  const handleCheck = (e) => {
    setValidate(e.target.checked)
    onChange(e.target.checked)
  }
  return (
    <Checkbox {..._props} checked={value} onChange={handleCheck}>
      Email me special savings and updates about Discount Drug Network's prescription discount card program.
    </Checkbox>
  )
}

const TextSendForm = ({ toggleModal, messageBody }) => {
  const [form] = Form.useForm()
  const DiscountCardUrl = window.location.href;
  const [loading, setLoading] = useState(false);

  const handleSendText = async (values) => {
    setLoading(true);
    try {
      await sendTextMessage({ ...messageBody, ...values, DiscountCardUrl })
      toast.success('Message sent successfully.')
      toggleModal(false)
      setLoading(false);
    } catch (error) {
      setLoading(false);
      console.error(error)
      toast.error('Message failed to send.')
    }

    if (values.subscribe) {
      try {
        await subscribeEmail({ ...values })
      } catch (error) {
        console.error(error)
        toast.error('Email subscription failed to send')
      }
    }
  }

  const normalizeInput = (value, previousValue) => {
    if (!value) return value;
    const currentValue = value.replace(/[^\d]/g, '');
    const cvLength = currentValue.length;

    if (!previousValue || value.length > previousValue.length) {
      if (cvLength < 4) return currentValue;
      return `${currentValue.slice(0, 3)}-${currentValue.slice(3, 6)}-${currentValue.slice(6, 10)}`;
    }
  };

  return (
    <div className='c-coupon-form'>
      <div className='c-coupon-form__title'>Text Card</div>
      <div className='c-coupon-form__sub-title'>
        Enter your phone number to text this card.
      </div>
      <Form
        form={form}
        onFinish={handleSendText}
      >
        <Row {...rowProps}>
          <Col {...colMdProps}>
            <Form.Item name='phoneNumber' rules={[{ required: true, message: 'Please enter phone number', pattern: new RegExp(/^(\+\d{1,2}\s)?\(?\d{3}\)?[\s.-]\d{3}[\s.-]\d{4}$/) }]} normalize={normalizeInput}>
              <FormInput
                required
                label='MOBILE PHONE NUMBER'
                placeholder='123-456-7890'
                autoFocus
              />
            </Form.Item>
          </Col>
        </Row>
        {loading ? (
            <>
              <img src="/images/loading.gif" width={32} height={32} alt="Loading" /> Sending...
            </>
        ) : (
            <PrimaryButton className='c-coupon-form__submit' type='submit'>
              Send Text
            </PrimaryButton>
        )}
      </Form>
      <div className='c-coupon-form__footer'>
        By entering your phone number and selecting Send Text you agree that Msg & data rates may apply. Terms and privacy information are available <a href="https://www.discountdrugnetwork.com/privacy-policy-terms-of-use">here</a>.
      </div>
    </div>
  )
}

export default TextSendForm
