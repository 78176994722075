import React, { useState, useEffect } from 'react';

const COOKIE_CONSENT_KEY = 'cookieConsent';

const Footer = ({ isFrame }) => {
  const [showCookieConsent, setShowCookieConsent] = useState(false);
  const [isEmbeddingSite, setIsEmbeddingSite] = useState(false);

  useEffect(() => {
    const hasConsent = localStorage.getItem(COOKIE_CONSENT_KEY);
    const referrer = document.referrer.includes('ddnrxsavings.com');
    setIsEmbeddingSite(referrer);

    if (!hasConsent && !isFrame && !referrer) {
      setShowCookieConsent(true);
    }
  }, [isFrame]);

  const handleCookieConsent = () => {
    localStorage.setItem(COOKIE_CONSENT_KEY, 'true');
    setShowCookieConsent(false);
  };

  // If the embedding site is 'ddnrxsavings.com', do not render the footer at all
  if (isEmbeddingSite) return null;

  return (
    <>
      {showCookieConsent && (
        <div className="cookie-consent-banner">
          <div className="cookie-consent-content">
            <span className="cookie-consent-text">
              We use cookies to enhance your browsing experience, serve personalized ads or content, and analyze our traffic. By clicking "Accept All", you consent to our use of cookies.
              <a href="/privacy-policy-terms-of-use">View details</a>
            </span>
            <button className="cookie-consent-button" onClick={handleCookieConsent}>
              Accept All
            </button>
          </div>
        </div>
      )}
      <footer className='g-footer'>
        {!isFrame && (
          <div className='g-footer__partners'>
            <a href='https://partners.discountdrugnetwork.com' target="_blank" rel="noopener noreferrer" className='g-link'>
              Partner Portal Login
            </a>
          </div>
        )}
        <div className='g-footer__copyright'>
          <span>© {(new Date().getFullYear())} Discount Drug Network, LLC. All Rights Reserved.</span>
          <div className='g-footer__links'>
            <a href='/privacy-policy-terms-of-use' className='g-link'>
              Privacy Policy & Terms of Use
            </a>
          </div>
        </div>
      </footer>
    </>
  );
};

export default Footer;
