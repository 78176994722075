export const QUERY_MD = '(min-width: 992px)'

export const FORM = 'form'
export const STRENGTH = 'strength'
export const QUANTITY = 'quantity'
export const BRAND_GENERIC = 'brandindicator'
export const GB = 'gb'
export const GSN = 'gsn'
export const QTY = 'qty'

export const INDEPENDENT = 'Independent'

export const CATEGORIES = {
  // National (1) and Grocery (2) have been combined into one cateogory
  '1': { order: 1 },
  '0': { order: 2, title: 'Other Pharmacies', icon: 'store' }
}

export const CATEGORY_FIELD = 'ddnPharmacyCategory'

export const CUSTOM_QUANTITY = "CUSTOM QUANTITY"
export const CUSTOM_QUANTITY_PLACEHOLDER = "e.g. 100"
