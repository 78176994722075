import React from 'react'
import { useSelector } from 'react-redux'
import SearchInput from '../SearchInput'
import SearchFilters from '../SearchFilters'
import { selectDrugName, selectResultNames } from 'features/search/redux/searchSelector'
import { selectIsIframe } from 'features/auth/redux/authSelector'

const ResultSearch = () => {
  const drug = useSelector(selectDrugName);
  const names = useSelector(selectResultNames);
  const isFrame = useSelector(selectIsIframe);
  if (!drug) return null;
  let drugName = drug.drugName;
  const genericDrug = names.find(name => name.brandGeneric === "G");
  const genericName = genericDrug?.drugName || "";

  if (drug.brandGeneric === "B" && genericName !== "") drugName += ` (Brand for ${genericName})`

  return (
    <div className={`
      c-results-search
      ${!drugName && isFrame ? 'c-results-search--iframe' : ''}
    `}>
      <SearchInput resultsMode />
      {
        Boolean(drugName) && (
          <div className='c-results-search__drugname'>
            {drugName}
          </div>
        )
      }
      <SearchFilters visible={Boolean(drugName)} />
    </div>
  )
}

export default ResultSearch
