import React from 'react'
import { LoadingOutlined } from '@ant-design/icons'
import { Spin } from 'antd'

const antIcon = <LoadingOutlined style={{ fontSize: 24, color: 'white' }} spin />

export const PrimaryButton = ({ 
  className = '', loading = false, disabled, size, full, slim, children, ..._props
}) => {
  const style = `
    g-btn g-btn--primary
    ${size ? `g-btn--primary--${size}` : ''}
    ${full ? 'g-btn--primary--full' : ''}
    ${slim ? 'g-btn--primary--slim' : ''}
    ${loading ? 'g-btn--primary--loading': ''}
    ${disabled ? 'g-btn--primary--disabled' : ''}
    ${className}
  `
  return (
    <button className={style}
      disabled={disabled}
      {..._props}
    >
      {children}
      {loading && <Spin indicator={antIcon} />}
    </button>
  )
}

export const SecondaryButton = ({ className = '', disabled, onClick, children }) => {
  const style = `
    g-btn g-btn--secondary
    ${disabled ? 'g-btn--secondary--disabled' : ''}
    ${className}
  `
  return (
    <button className={style}
      disabled={disabled}
      onClick={onClick}
    >
      {children}
    </button>
  )
}
