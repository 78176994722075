import { createAction } from '@reduxjs/toolkit'
import * as searchService from 'utils/api/search'
import searchTypes from './searchTypes'
import { clearQueryIdentifier } from 'utils'

const SEARCH_START_LENGTH = 3

export const setSearchZip = createAction(searchTypes.SET_SEARCH_ZIPCODE)
export const setSearchDrugName = createAction(searchTypes.SET_SEARCH_DRUG_NAME)
export const setSearchBrandIndicator = createAction(searchTypes.SET_SEARCH_BRANDINDECATOR)
export const setSearchFilter = createAction(searchTypes.SET_SEARCH_FILTER)

const findDrugsBegin = createAction(searchTypes.REQUEST_DRUGS_BEGIN)
const findDrugsSuccess = createAction(searchTypes.REQUEST_DRUGS_SUCCESS)
const findDrugsFail = createAction(searchTypes.REQUEST_DRUGS_FAIL)

export const findDrugs = (query, filter) => async (dispatch) => {
  dispatch(findDrugsBegin())
  try {
    const singleIdQuery = clearQueryIdentifier(query)
    const res = await searchService.findDrugs(singleIdQuery)
    dispatch(findDrugsSuccess({ res, query, filter }))
  } catch (e) {
    dispatch(findDrugsFail(e))
  }
}

const autoCompleteDrugNameBegin = createAction(searchTypes.AUTOCOMPLETE_DRUG_NAME_BEGIN)
const autoCompleteDrugNameFail = createAction(searchTypes.AUTOCOMPLETE_DRUG_NAME_FAIL)
export const autoCompleteDrugNameSuccess = createAction(searchTypes.AUTOCOMPLETE_DRUG_NAME_SUCCESS)

export const autoCompleteDrugName = (prefixText) => async (dispatch, getState) => {
  dispatch(setSearchDrugName(prefixText))
  try {
    if (!prefixText) {
      dispatch(autoCompleteDrugNameSuccess([]))
    } else if (prefixText.length >= SEARCH_START_LENGTH) {
      dispatch(autoCompleteDrugNameBegin())
      const { drugNames } = await searchService.autoCompleteDrugName(prefixText)
      const { search: { input } } = getState()
      if (prefixText === input.drugName) {
        dispatch(autoCompleteDrugNameSuccess(drugNames))
      }
    }
  } catch (e) {
    dispatch(autoCompleteDrugNameFail(e))
  }
}

export const resetDiscountInfo = createAction(searchTypes.RESET_DISCOUNT_INFO)
const getDiscountInfoBegin = createAction(searchTypes.REQUEST_DISCOUNT_INFO_BEGIN)
const getDiscountInfoSuccess = createAction(searchTypes.REQUEST_DISCOUNT_INFO_SUCCESS)
const getDiscountInfoFail = createAction(searchTypes.REQUEST_DISCOUNT_INFO_FAIL)

export const getDiscountInfo = () => async (dispatch) => {
  dispatch(getDiscountInfoBegin())
  try {
    const res = await searchService.getDiscountInfo()
    dispatch(getDiscountInfoSuccess(res))
  } catch (e) {
    dispatch(getDiscountInfoFail(e))
  }
}
