import React from 'react'

export default ({ width = 8, fill = '#000000', className = '', onClick }) => (
  <div className={`g-icon__close ${className}`} onClick={onClick}>
    <svg width={`${width}px`} height={`${width}px`} viewBox='0 0 28 28' version="1.1" xmlns="http://www.w3.org/2000/svg">
      <g id="Symbols" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g id="Icons" transform="translate(-676.000000, -203.000000)" fill={fill}>
          <g id="Icons/close" transform="translate(674.000000, 201.000000)">
            <path d="M17.8383779,16 L29.111382,2.56257658 C29.300339,2.33926374 29.1414433,2 28.8494188,2 L25.4224256,2 C25.2205851,2 25.0273336,2.09018403 24.8942048,2.24478523 L15.5966605,13.3288323 L6.29911626,2.24478523 C6.17028192,2.09018403 5.97703043,2 5.77089549,2 L2.34390226,2 C2.05187778,2 1.8929821,2.33926374 2.08193912,2.56257658 L13.3549432,16 L2.08193912,29.4374182 C1.99550207,29.5390641 1.97620007,29.6817499 2.03252407,29.802708 C2.08884808,29.9236661 2.21047543,30.0007283 2.34390226,30 L5.77089549,30 C5.97273595,30 6.16598745,29.9098108 6.29911626,29.7552096 L15.5966605,18.6711626 L24.8942048,29.7552096 C25.0230392,29.9098108 25.2162907,30 25.4224256,30 L28.8494188,30 C29.1414433,30 29.300339,29.6607311 29.111382,29.4374182 L17.8383779,16 Z" id="Path"></path>
          </g>
        </g>
      </g>
    </svg>
  </div>
)
