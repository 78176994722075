import React, { useState } from 'react'
import { Form, Row, Col } from 'antd'
import { FormInput, PrimaryButton } from 'shared/components'
import { rowProps, colMdProps } from '../TextSendForm'
import { sentEmailMessage } from 'utils/api/message'
import toast from 'utils/toast'


const EmailSendForm = ({ toggleModal, emailBody, DiscountCardUrl = null }) => {
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);

  const handleSendEmail = async (values) => {
      setLoading(true);

    try {
      const res = await sentEmailMessage({ ...emailBody, ...values, DiscountCardUrl });
      setLoading(false);
      if (res === undefined) {
        toast.error('Message failed to send.')
      } else {
        toggleModal(false);
        toast.success('Message sent successfully.')
      }
    } catch (error) {
      console.error(error);
      setLoading(false);
      toast.error('Message failed to send.')
    }
  }

  return (
    <div className='c-coupon-form'>
      <div className='c-coupon-form__title'>Email Card</div>
      <div className='c-coupon-form__sub-title'>
        Enter your email address to email this card.
      </div>
      <Form
        form={form}
        onFinish={handleSendEmail}
      >
        <Row {...rowProps}>
          <Col xs={24}>
            <Form.Item name='emailAddress' rules={[{ required: true, message: 'Please enter email' }]}>
              <FormInput full required label='EMAIL ADDRESS' autoFocus placeholder='john.dough@email.com' type='email' />
            </Form.Item>
          </Col>
        </Row>
        <Row {...rowProps} style={{display: 'none'}}>
          <Col {...colMdProps}>
            <Form.Item name='firstName'>
              <FormInput label='FIRST NAME' placeholder='ENTER FIRST NAME' />
            </Form.Item>
          </Col>
        </Row>
          {loading ? (
              <>
                  <img src="/images/loading.gif" width={32} height={32} alt="Loading" /> Sending...
              </>
          ) : (
              <PrimaryButton className='c-coupon-form__submit' type='submit'>
                  Send Email
              </PrimaryButton>
          )}
      </Form>
      <div className='c-coupon-form__footer'>
      By entering your email address and selecting Send Email you agree that Msg & data rates may apply. Terms and privacy information are available <a href="https://www.discountdrugnetwork.com/privacy-policy-terms-of-use">here</a>.
      </div>
    </div>
  )
}

export default EmailSendForm
