import { post } from 'bootstrap/axios'

export const authLogin = async (groupId) => {
  const url = `/gateway/auth/login?groupId=${groupId}`
  return await post(url, null, { isPublic: true })
}

export const refreshToken = async (accessToken, refreshToken) => {
  const url = '/gateway/auth/refresh'
  return await post(url, { accessToken, refreshToken }, { isPublic: true })
}
