import { createReducer } from '@reduxjs/toolkit'
import { defaultGroupId } from 'shared/constants'
import authTypes from './authTypes'
import { content_bg, light_green } from 'shared/assets/scss/settings/_color.scss'

const defaultState = {
  token: null,
  refreshToken: null,
  expiresIn: null,
  refreshAt: null,
  expiresAt: null,
  iframe: false, // if it is in iframe
  groupId: defaultGroupId,
  containerBgColor: `#${content_bg}`,
  resultSearchBarBgColor: `#${light_green}80`,
}

export default createReducer(defaultState, {
  [authTypes.LOGIN_BEGIN]: state => {
    state.token = null
  },
  [authTypes.LOGIN_SUCCESS]: (state, action) => {
    const { accessToken, refreshToken, expiresIn } = action.payload
    state.token = accessToken
    state.refreshToken = refreshToken
    if (expiresIn) {
      state.expiresIn = expiresIn
    }
    if (state.expiresIn) {
      const timestamp = Math.floor(Date.now() / 1000)
      state.refreshAt = timestamp + expiresIn / 2
      state.expiresAt = timestamp + expiresIn
    }
  },
  [authTypes.SET_IFRAME_STATE]: (state, action) => {
    state.iframe = action.payload
  },
  [authTypes.REFRESH_TOKEN_FAIL]: (state, action) => {
    state.token = null
    state.refreshToken = null
  },
  [authTypes.SET_IFRAME_GROUP_ID]: (state, action) => {
    state.groupId = action.payload
  },
  [authTypes.SET_IFRAME_CONTAINER_BG_COLOR]: (state, action) => {
    state.containerBgColor = action.payload
    state.resultSearchBarBgColor = action.payload
  }
})
