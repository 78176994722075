import React from 'react'

export default ({ width = 28, fill = '#E7131A' }) => (
  <svg width={`${width}px`} height={`${width * 0.89}px`} viewBox='0 0 28 25' version="1.1" xmlns="http://www.w3.org/2000/svg">
    <g id="Symbols" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g id="Icons" transform="translate(-868.000000, -205.000000)" fill={fill}>
        <g id="Icons/error" transform="translate(866.000000, 201.000000)">
          <path d="M14.5000848,22.7489395 C14.5000848,23.5773198 15.1716197,24.2488547 16,24.2488547 C16.8283803,24.2488547 17.4999152,23.5773198 17.4999152,22.7489395 C17.4999152,21.9205592 16.8283803,21.2490243 16,21.2490243 C15.1716197,21.2490243 14.5000848,21.9205592 14.5000848,22.7489395 Z M15.0000566,13.2494768 L15.0000566,18.9991516 C15.0000566,19.1366438 15.1125502,19.2491375 15.2500424,19.2491375 L16.7499576,19.2491375 C16.8874498,19.2491375 16.9999434,19.1366438 16.9999434,18.9991516 L16.9999434,13.2494768 C16.9999434,13.1119846 16.8874498,12.999491 16.7499576,12.999491 L15.2500424,12.999491 C15.1125502,12.999491 15.0000566,13.1119846 15.0000566,13.2494768 Z M29.8648408,26.9986991 L16.865576,4.49997172 C16.671837,4.16561563 16.3374809,4 16,4 C15.6625191,4 15.3250382,4.16561563 15.134424,4.49997172 L2.13515924,26.9986991 C1.75080598,27.6674113 2.23202876,28.4986143 3.00073528,28.4986143 L28.9992647,28.4986143 C29.7679712,28.4986143 30.249194,27.6674113 29.8648408,26.9986991 Z M5.3818506,26.1268734 L16,7.74666308 L26.6181494,26.1268734 L5.3818506,26.1268734 Z" id="Shape"></path>
        </g>
      </g>
    </g>
  </svg>
)
