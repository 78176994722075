import React from 'react'
import { Container } from 'shared/components'
import SearchInput from '../SearchInput'
import HomeLogos from '../HomeLogos'

const HomeSearch = ({ isFrame }) => {
  const renderTopContent = () => {
    if (isFrame) return null
    return (
      <>
        <div className='c-search-form__intro'>
          Take <span>control</span> of your prescription costs.
        </div>
        <div className='c-search-form__free'>
          Free. No memberships. Up to 80% savings!
        </div>
      </>
    )
  }

  return (
    <Container>
      <div className={`c-search-form ${isFrame ? 'c-search-form--iframe' : ''}`}>
        {renderTopContent()}
        <SearchInput />
        <HomeLogos isFrame={isFrame} />
      </div>
    </Container>
  )
}

export default HomeSearch
