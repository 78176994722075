import React, { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { useLocation, Link } from 'react-router-dom'
import Media from 'react-media'
import { setIframeState } from 'features/auth/redux/authActions'
import { MenuVertical } from 'shared/components'
import { QUERY_MD } from 'shared/constants'
import { parseQueryString } from 'utils'

const menus = [
  { title: 'Get a Card', url: 'get-card' },
  { title: 'About Us', url: 'about-us' },
  { title: 'Contact', url: 'contact' }
]

const title = 'Discount Drug Network'

const Header = ({ isFrame }) => {
  const dispatch = useDispatch()
  const location = useLocation()
  const path = location.pathname
  const { iframe } = parseQueryString(location.search)

  const renderMenus = () => (
    menus.map(({ title, url }) => (
      <Link key={url} to={url} className={path.includes(url) ? 'g-header__menu--active' : ''}>
        {title}
      </Link>
    ))
  )

  useEffect(() => {
    const { iframe } = parseQueryString(location.search)
    if (iframe) {
      dispatch(setIframeState(true))
    }
  }, [dispatch, location])

  if (iframe || isFrame) return null

  return (
    <header className='g-header'>
      <div className='g-header__notice'>
        <div>For customer and pharmacy support call&nbsp;<a href="tel:8775375537">1-877-537-5537</a></div>
      </div>
      <div className='g-header__title'>
        <Link to='/' className='g-header__logo'>
          <img src='/images/ddn-logo.png' alt={title} />
        </Link>
        <div className='g-header__menu'>
          <Media queries={{ isMd: QUERY_MD }}>
            {({ isMd }) => (
              isMd
                ? renderMenus()
                : (
                  <MenuVertical>
                    <div>
                      {renderMenus()}
                    </div>
                  </MenuVertical>
                )
            )}
          </Media>
        </div>
      </div>
    </header>
  )
}

export default React.memo(Header)
