import React from 'react'

export default ({ width = 28, fill = '#000000', className = "", style = {}, onClick, disabled = false }) => (
  <div className={`g-icon__close ${className}`} disabled={disabled} style={style} onClick={onClick}>
    <svg width={`${width}px`} height={`${width * 0.82}px`} viewBox='0 0 28 23' version="1.1" xmlns="http://www.w3.org/2000/svg">
      <g id="Symbols" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g id="Icons" transform="translate(-612.000000, -206.000000)" fill={fill}>
          <g id="Icons/check" transform="translate(610.000000, 201.000000)">
            <path d="M29.7249461,5 L27.3265117,5 C26.9902506,5 26.6711456,5.15440564 26.4652714,5.41861085 L12.3182832,23.3399592 L5.53472863,14.7447117 C5.32682297,14.4807465 5.00949757,14.3265086 4.67348826,14.3261008 L2.27505394,14.3261008 C2.04516109,14.3261008 1.91820534,14.5903061 2.05888604,14.7687304 L11.4570429,26.6751211 C11.8962411,27.2309814 12.7403253,27.2309814 13.1829548,26.6751211 L29.941114,5.43919827 C30.0817947,5.26420521 29.9548389,5 29.7249461,5 Z" id="Path"></path>
          </g>
        </g>
      </g>
    </svg>
  </div>
)
