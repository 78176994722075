import React from 'react'

export default ({ width = '465px', height = '261.56px' }) => {
  return (
    <div className='c-discountcard-image'>
      <div className='c-discountcard-image__bg' />
      <img width={width} height={height} src='/images/card.png' alt='Visual of DDN discount prescription card' />
    </div>
  )
}
