import { Row, Col } from 'antd'
import React from 'react'
import { useHistory } from 'react-router-dom'
import { Container, PrimaryButton, SEO } from 'shared/components'

const rowProps = {
  type: 'flex',
  gutter: 20
}

const colSmProps = {
  xs: 24,
  md: 6
}

const AboutUs = () => {
  const history = useHistory()

  const handleContact = () => history.push('/contact')

  return (
    <div className='c-aboutus'>
      <SEO title='About Us - Discount Drug Network' description='About the Discount Drug Network Program' />
      <div className='c-heading c-heading--center c-aboutus__heading'>
        Discount Drug Network
        <span className='c-heading__green'> saves you time and money </span>
        by providing transparent prescription pricing and pharmacy options.
      </div>
      <p className='c-aboutus__paragraph'>
        Put the power of a prescription network to work for you!
        Discount Drug Network connects people and organizations
        across the United States with presription savings up to 80%.
      </p>
      <div className='c-heading c-heading--sm u-mb6'>
        Local
      </div>
      <p className='c-paragraph'>
        Search your local area to find the most convenient location for you to save money.
        Prices usually vary within a local area and with Discount Drug Network,
        you will search your local area spanning national pharmacies,
        grocery stores, and independent pharmacies to find the best price local to you.
      </p>
      <div className='c-aboutus__divide' />
      <div className='c-heading c-heading--sm u-mb6'>
        Shoppable
      </div>
      <p className='c-paragraph'>
        Many people do not know that prescription medication prices vary at different stores.
        Discount Drug Network makes prescription medications
        easy to compare pricing to find the most convenient way to save money.
      </p>
      <div className='c-aboutus__divide' />
      <div className='c-heading c-heading--sm u-mb6'>
        Easy
      </div>
      <p className='c-paragraph u-mb60'>
        Saving money on your prescriptions is made easy by Discount Drug Network.
        Search our Discount Drug Network for your medication,
        select the best price and location for you,
        and print, text or email the savings information to present to your pharmacist.
      </p>
      <div className='c-heading c-aboutus__heading c-aboutus__heading__h2'>
        B2B Prescription Cost Savings Program
      </div>
      <p className='c-paragraph u-mb25'>
        Companies partner with Discount Drug Network to create a custom formulary pharmacy network
        to optimize the savings for company employees and their families.
        Discount Drug Network’s knowledge and relationships spanning the healthcare industry enables companies
        to refine their offerings to have a truly custom pharmacy solution.
      </p>
      <p className='c-paragraph u-mb25'>
        The patients and providers of Discount Drug Network’s custom formulary pharmacy network
        should expect to save money on prescriptions, have transparency on prescription costs,
        and increase their medication adherence.
      </p>
      <Row {...rowProps}>
        <Col {...colSmProps}>
          <div className='c-heading c-heading--xs'>
            No Barriers
          </div>
          <ul>
            <li>No user ID or PW required</li>
            <li>No new app or portal required</li>
          </ul>
        </Col>
        <Col {...colSmProps}>
          <div className='c-heading c-heading--xs'>
            Card Distribution
          </div>
          <ul>
            <li>Customized cards with employer brand</li>
            <li>Physical and digital cards available</li>
          </ul>
        </Col>
        <Col {...colSmProps}>
          <div className='c-heading c-heading--xs'>
            Custom Search
          </div>
          <ul>
            <li>Medication price search function available within employer site</li>
            <li>No point-of-sale cost suprises for member</li>
          </ul>
        </Col>
        <Col {...colSmProps}>
          <div className='c-heading c-heading--xs'>
            Training
          </div>
          <ul>
            <li>No cost for employee communications or on-boarding</li>
            <li>Branded printed custom program materials for members</li>
          </ul>
        </Col>
      </Row>
      <Container additionalClassName='c-aboutus__container'>
        <div className='c-heading c-heading--center c-heading--xs u-mb16'>
          Contact us to learn more about custom company offerings
        </div>
        <PrimaryButton size='md' onClick={handleContact}>
          Contact Us
        </PrimaryButton>
      </Container>
      <div className='c-heading c-aboutus__heading c-aboutus__heading__h2' id="affiliates">
        Affiliate Opportunities
      </div>
      <p className='c-paragraph u-mb25'>
        Affiliates have an outstanding opportunity to partner with Discount Drug Network
        to connect more people with the incredible savings Discount Drug Network offers.
        By joining Discount Drug Network, Affiliates have a unique opportunity to help
        spread the word about the power of Discount Drug Network while building their own business and creating real income for themselves.
      </p>
      <p className='c-paragraph u-mb25'>
        Affiliates play a major role in distributing information and easy access to this powerfulprescription savings program
        to people who otherwise may not have been aware.
        Affiliates of Discount Drug Network are set up for success right from the get go and receive a complete start up
        package including premium printed cards and marketing material,
        ther own website for electronic distribution and daily tracking via our custom portal.
      </p>
      <p className='c-paragraph u-mb25'>
        One thing that makes our Affiliate program such a huge success is our availability and personal touch with our Affiliates.
        To ensure your success, Affiliates also receive one on one training plus on-going coaching direct from our National Affiliate Manager.
        As a strategic marketing partner with Discount Drug Network,
        affiliates can build a reliable and in many cases, substantial income just for distributing cards and helping people gain access to these unbelievable savings.
      </p>
      <p className='c-paragraph u-mb25'>
        With a consistent need for access to affordable medications and over 4 Billion prescriptions being written on an annual basis in the United States,
        Affiliates have an outstanding opportunity to benefit from offering access to this powerful program. Now is the time to join our
        Discount Drug Network family and become an Affiliate.
        Don’t hesitate, contact us today and we will get you started immediately!
      </p>
      <Container additionalClassName='c-aboutus__container'>
        <div className='c-heading c-heading--center c-heading--xs u-mb16'>
          Contact us to apply to be an affiliate of Discount Drug Network
        </div>
        <PrimaryButton size='md' onClick={handleContact}>
          Contact Us
        </PrimaryButton>
      </Container>
    </div>
  )
}

export default AboutUs
