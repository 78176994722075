import React from 'react'
import { useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import map from 'lodash/map'
import {
  selectDrugsResults,
  selectDiscountInfo,
  selectDrugsResultsCount,
  selectLoadingResults
} from 'features/search/redux/searchSelector'
import { selectIframeGroupId, selectIsIframe } from 'features/auth/redux/authSelector'
import { Container, ListLoading } from 'shared/components'
import { queryStringify, parseQueryString } from 'utils'
import ResultItem from './ResultItem'

const SearchResults = () => {
  const drugsByCategory = useSelector(selectDrugsResults)
  const drugsCount = useSelector(selectDrugsResultsCount)
  const isLoadingResults = useSelector(selectLoadingResults)
  const history = useHistory()
  const discountInfo = useSelector(selectDiscountInfo)
  const iframeGroupId = useSelector(selectIframeGroupId)
  const isFrame = useSelector(selectIsIframe)


  if (isLoadingResults) return <ListLoading />
  if (!drugsCount) return null

  const handleGetDiscount = (pharmacyName) => {
    const search = history.location.search
    const query = parseQueryString(search)
    const { groupID } = discountInfo
    const discountGroupId = iframeGroupId || groupID
    const couponQueryStr = queryStringify({ ...query, pharmacy: pharmacyName, groupId: discountGroupId })
    history.push(`/get-coupon${couponQueryStr}`)
  }

  return (
    <div className='c-results'>
      {map(drugsByCategory, ({ title, icon, drugsGroup: drugsByPharmacy }) => (
        <Container
          key={title !== undefined ? title : 'national and grocery'}
          additionalClassName={` 
          ${isFrame ? 'c-results__container' : 'c-results__container c-results__container__padding'}
        `}>
          {title &&
            <div className='c-results__title'>
              <img src={`/images/${icon}.png`} alt={title} />
              {title}
            </div>
          }
          {map(drugsByPharmacy, (results, pharmacyName) => {
            return (
              <ResultItem
                key={pharmacyName}
                results={results}
                handleGetDiscount={handleGetDiscount}
              />
            );
          })}
        </Container>
      ))}
    </div>
  );
};

export default SearchResults;
