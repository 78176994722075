import React from 'react'
import { toast } from 'react-toastify'
import { InfoIcon, ErrorIcon } from 'shared/components'

const renderMsg = (msg, success) => {
  return (
    <div className='g-toast__msg'>
      {success ? <InfoIcon /> : <ErrorIcon width={14} />}
      {msg}
    </div>
  )
}

export default {
  success: (msg, options = {}) => {
    return toast.success(renderMsg(msg, true), {
      ...options,
      className: 'g-toast__container--success'
    })
  },
  error: (msg, options = {}) => {
    return toast.error(renderMsg(msg), {
      ...options,
      className: 'g-toast__container--error'
    })
  },
  options: {
    className: 'g-toast',
    position: 'top-right',
    autoClose: 3000,
    hideProgressBar: true,
    newestOnTop: false,
    closeOnClick: true,
    pauseOnHover: true,
    closeButton: false,
    draggable: false
  }
}
