import { createSelector } from 'reselect'

export const selectDrugsResults = state => state.search.drugs
export const selectDrugsResultsCount = state => state.search.count
export const selectDrugNames = state => state.search.drugNames
export const selectSearchDrugName = state => state.search.input.drugName
export const selectSearchZip = state => state.search.input.zip
export const selectSearchBrandIndicator = state => state.search.input.brandIndicator

export const selectResultsForms = state => state.search.forms
export const selectResultsQuantities = state => state.search.quantities
export const selectResultsStrengths = state => state.search.strengths
export const selectResultsFilters = state => state.search.filters

export const selectResultDrugInfo = state => state.search.drugInfo
export const selectDiscountInfo = state => state.search.discount

export const selectLoadingDrugNames = state => state.search.meta.isLoadingDrugNames
export const selectLoadingResults = state => state.search.meta.isLoadingDrugResults
export const selectLoadingDiscount = state => state.search.meta.isLoadingDiscountInfo
export const selectDrugDataLoaded = state => state.search.meta.drugDataLoaded

export const selectResultNames = state => state.search.names
export const selectDrugName = createSelector(
  [selectResultNames, selectResultDrugInfo, selectSearchBrandIndicator],
  (names, info, bi) => {
    const item = names && names.find(({ brandGeneric, isSelected }) => (isSelected && bi === brandGeneric) || bi === brandGeneric)
    return item || info
  }
)

export const selectLoadingCoupon = createSelector(
  [selectLoadingResults, selectLoadingDiscount],
  (isLoadingDrugResults, isLoadingDiscountInfo) => {
    return isLoadingDrugResults || isLoadingDiscountInfo
  }
)

export const selectFilterInputs = createSelector(
  [
    selectResultsFilters,
    selectResultsForms,
    selectResultsStrengths
  ],
  (filters, forms, strengths) => {
    const { form, strength } = filters
    const formItem = forms.find(f => f.gsn === form)
    const strengthItem = strengths.find(s => s.gsn === strength)
    return {
      ...filters,
      form: formItem && formItem.form,
      strength: strengthItem && strengthItem.strength
    }
  }
)
