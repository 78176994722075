import {
  getActionTypes,
  getMultiActionTypes
} from 'bootstrap/redux/action'

const prefix = 'SEARCH'

const unitTypes = getActionTypes([
  'SET_DOSAGE',
  'SET_SEARCH_DRUG_NAME',
  'SET_SEARCH_ZIPCODE',
  'SET_SEARCH_BRANDINDECATOR',
  'SET_SEARCH_FILTER',
  'RESET_DISCOUNT_INFO'
], prefix)

const multiTypes = getMultiActionTypes([
  'REQUEST_DRUGS',
  'REQUEST_DISCOUNT_INFO',
  'AUTOCOMPLETE_DRUG_NAME'
], prefix)

export default {
  ...unitTypes,
  ...multiTypes
}
