import React from 'react'
import { InfoIcon, ErrorIcon } from '../Icon'

export const GoNotice = React.memo(({ hide, error, words }) => {
  if (hide) {
    return null
  }
  return (
    <div className={`u-align-left g-notice ${error ? 'g-notice--error' : ''}`}>
      {error ? <ErrorIcon width={16} /> : <InfoIcon />} {words}
    </div>
  )
})
