import React, { useEffect } from 'react'
import { MailCardForm, SendDiscountCard } from 'features/getcard/components'
import { SEO } from 'shared/components'

const GetCard = () => {

  useEffect(() => {
    window.scrollTo({ top: 0 })
  }, []);

  return (
    <div className='c-card-screen'>
      <SEO title='Get a Card - Discount Drug Network' description='Prescription Discount Card by Discount Drug Network' />
      <div className='c-card-screen__title'>
        <span></span><span>Present this discount card to your pharmacist.</span>
      </div>
      <div className='c-card-screen__text'>
      </div>
      <SendDiscountCard />
      <MailCardForm />
      <p class="c-card-screen__date">
        {new Date().toLocaleString() + ''}
      </p>
    </div>
  )
}

export default GetCard
