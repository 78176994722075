import React from 'react'
import { SEO } from 'shared/components'

const Changelog = () => {
    return (
        <div className='c-changelog'>
            <SEO title='Changelog - Discount Drug Network' description='The Discount Drug Network changelog' />
            <div className='c-heading c-heading--center c-changelog__heading'>
                Discount Drug Network
                <span className='c-heading__green'> API </span>
                Changelog
            </div>
            <div className='c-changelog__version'>
                <h3>Version <strong>1.2</strong></h3>
                <time>January 26, 2023</time>
                <ul>
                    <li>Integrated with Twilio & Send Grid to bring request card by email and SMS</li>
                    <li>Added custom quantity feature</li>
                    <li>Implemented option to change medication type</li>
                    <li>Fixed formatting of items and vertical alignment</li>
                    <li>Added time to print screens</li>
                </ul>
            </div>
            <div className='c-changelog__version'>
                <h3>Version <strong>1.1</strong></h3>
                <time>August 14, 2022</time>
                <ul>
                    <li>Updated mobile optimizations</li>
                    <li>Changed formatting for "More Locations"</li>
                    <li>Added original price and savings percentage</li>
                    <li>Removed search result number</li>
                    <li>Update ordering of quantity</li>
                </ul>
            </div>
            <div className='c-changelog__version'>
                <h3>Version <strong>1.0</strong></h3>
                <time>January 1, 2022</time>
                <ul>
                    <li><span role="img" aria-label="Sparkle">✨</span> Launched Discount Drug Network API</li>
                </ul>
            </div>
        </div>
    )
}

export default Changelog
