import React from 'react';
import { useHistory } from 'react-router-dom'
import DiscountCard from 'shared/assets/images/DiscountDrugNetwork';
import { SecondaryButton } from 'shared/components';

export default () => {
  const history = useHistory()
  const handleClick = () => {
    history.push('/get-card')
  }

  return (
    <div className='c-card-home'>
      <DiscountCard />
      <div className='c-card-home__info'>
        <div className='c-card-home__title'>
          <p>Get your free prescription</p>
          <p>discount card</p>
        </div>
        <div className='c-card-home__text'>
          Print, text, email or even receive a free prescription card in the mail.
          Your pharmacist will use the identification numbers on this card to
          automatically apply savings to your prescription.
        </div>
        <SecondaryButton onClick={handleClick}>
          Get your card
        </SecondaryButton>
      </div>
    </div>
  )
}
