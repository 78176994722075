import React, { useRef, useState, useEffect, useCallback, Fragment } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { SearchOutlined } from '@ant-design/icons'
import Media from 'react-media'
import { Form, Row, Col, Spin, Modal, Input } from 'antd'
import { debounce } from 'lodash'
import { PrimaryButton, SecondaryButton, DdnInput } from 'shared/components'
import {
  DRUG_NAME_LABEL,
  DRUG_NAME_PLACEHOLDER,
  ZIP_CODE_LABEL,
  ZIP_CODE_PLACEHOLDER,
  QUERY_MD
} from 'shared/constants'
import {
  findDrugs,
  setSearchZip,
  setSearchDrugName,
  autoCompleteDrugName,
  autoCompleteDrugNameSuccess,
  setSearchBrandIndicator
} from 'features/search/redux/searchActions'
import {
  selectDrugNames,
  selectLoadingDrugNames,
  selectLoadingResults,
  selectSearchDrugName,
  selectSearchZip,
  selectDrugDataLoaded
} from 'features/search/redux/searchSelector'
import {
  selectIsIframe,
  selectIframeSearchBarBgColor
} from 'features/auth/redux/authSelector'
import { queryStringify, parseQueryString } from 'utils'
import { setFrameHeight } from 'utils/iframe'

export const rowProps = {
  type: 'flex',
  gutter: 8
}

export const colMdProps = {
  xs: 24,
  md: 12
}

const validateZip = (zip) => {
  if (!zip) {
    return false
  }
  return /(^\d{5}$)|(^\d{5}-\d{4}$)/.test(zip)
}

const SearchInput = ({ resultsMode }) => {
  const [showModal, setShowModal] = useState(false)
  // const [modalZipCode, setModalZipCode] = useState('')

  const openModal = () => {
    setShowModal(true)
  }

  const submitLocation = (zipcode) => {
    window.location.href += `&zip=${zipcode}`
  }

  const closeModal = () => {
    setShowModal(false)
    history.push('/')
  }

  const dispatch = useDispatch()
  const history = useHistory()
  const drugNameRef = useRef(null)
  const zipInputRef = useRef(null)

  const drugNames = useSelector(selectDrugNames)
  const isLoadingDrugNames = useSelector(selectLoadingDrugNames)
  const isLoadingDrugResults = useSelector(selectLoadingResults)
  const searchDrugName = useSelector(selectSearchDrugName)
  const searchZip = useSelector(selectSearchZip)
  const isDataLoaded = useSelector(selectDrugDataLoaded)
  const isFrame = useSelector(selectIsIframe)
  const iframeSearchBarBgColor = useSelector(selectIframeSearchBarBgColor)
  const iframeSearchBarBgColorStyle = isFrame && resultsMode
    ? { style: { backgroundColor: iframeSearchBarBgColor } }
    : {}

  const [drugName, setDrugName] = useState(searchDrugName)
  const [zip, setZipCode] = useState(searchZip)

  const handleSearchDrugs = async (e) => {
    if (e && e.preventDefault) {
      e.preventDefault()
    }

    if (!drugName || !zip)
      return

    dispatch(setSearchBrandIndicator("G"));
    const query = { drugName, zip }
    const queryStr = queryStringify(query)
    const url = `/get-discount${queryStr}`
    if (resultsMode) {
      await dispatch(findDrugs(query))
      history.replace(url)
    } else {
      history.push(url)
    }

    // Add this line to clear the drugNames (recommendations) after a search is triggered
    dispatch(autoCompleteDrugNameSuccess([]))
  }

  useEffect(() => {
    if (isFrame && isDataLoaded) {
      setFrameHeight()
    }
  }, [isFrame, isDataLoaded])

  useEffect(() => {
    (async () => {
      const search = history.location.search

      if (search) {
        const query = parseQueryString(search)
        if (query.zip && (query.drugName || query.gsn)) {
          setDrugName(query.drugName)
          dispatch(setSearchDrugName(query.drugName))
          setZipCode(query.zip)
          dispatch(setSearchZip(query.zip))
          await dispatch(findDrugs(query))
        } else if (!query.zip && !query.iframe) { // added check for iframe query parameter
          openModal()
        }
      }
    })()
  }, [dispatch, history])

  const handleAutoComplete = useCallback(
    debounce((prefix) => {
      dispatch(autoCompleteDrugName(prefix))
    }, 300),
    [dispatch]
  )

  const handlePrefixChange = (e) => {
    const prefix = e.target.value
    setDrugName(prefix)
    handleAutoComplete(prefix)
  }

  const updateZipCode = useCallback(
    debounce((val) => {
      dispatch(setSearchZip(val))
    }, 300),
    [dispatch]
  )

  const handleZipInputChange = (e) => {
    const zipcode = e.target.value
    setZipCode(zipcode)
    updateZipCode(zipcode)
  }

  const handleSelectDrugName = (name) => {
    setDrugName(name)
    dispatch(setSearchDrugName(name))
    dispatch(autoCompleteDrugNameSuccess([]))
    zipInputRef.current.focus()
  }

  const clearDrugNameInput = () => {
    setDrugName('')
    dispatch(setSearchDrugName(''))
    dispatch(autoCompleteDrugNameSuccess([]))
  }

  const clearZipCodeInput = () => {
    setZipCode('')
    dispatch(setSearchZip(''))
  }

  let searchBtnText = 'Find the lowest price!'
  if (resultsMode) {
    searchBtnText = !isLoadingDrugResults ? <SearchOutlined /> : ''
  }

  // const handleModalOk = () => {
  //   const query = { drugName: searchDrugName, zip: modalZipCode }
  //   const queryStr = queryStringify(query)
  //   const url = `/get-discount${queryStr}`
  //   closeModal()
  //   history.push(url)
  // }

  // const handleModalZipInputChange = (e) => {
  //   const zipcode = e.target.value
  //   setModalZipCode(zipcode)
  // }

  const renderZipAndBtn = () => (
    <Fragment>
      <div className='c-search-input__zipcode'>
        <div className='c-search-input__label'>{ZIP_CODE_LABEL}</div>
        <DdnInput
          placeholder={ZIP_CODE_PLACEHOLDER}
          value={zip}
          onChange={handleZipInputChange}
          clearInput={clearZipCodeInput}
          tabIndex={2 + drugNames.length}
          ref={zipInputRef}
        />
      </div>
      <PrimaryButton
        type='submit'
        disabled={!drugName || !validateZip(zip)}
        loading={isLoadingDrugResults}
        className={resultsMode ? 'c-search-input__lookup-btn' : ''}
        tabIndex={3 + drugNames.length}
      >
        {searchBtnText}
      </PrimaryButton>
    </Fragment>
  )

  const refProp = (idx) => {
    if (!idx) {
      return { ref: drugNameRef }
    }
    return {}
  }

  return (
    <Media queries={{ isMd: QUERY_MD }}>
      {({ isMd }) => (
        <form
          onSubmit={handleSearchDrugs}
          className={`
            c-search-input
            ${resultsMode ? 'c-search-input--results' : ''}
            ${!isMd ? 'c-search-input--sm' : ''}
            ${resultsMode && !isMd ? 'c-search-input--results-sm' : ''}
          `}
          {...iframeSearchBarBgColorStyle}
        >
          <div className='c-search-input__drug'>
            <div className='c-search-input__label'>{DRUG_NAME_LABEL}</div>
            <DdnInput
              placeholder={DRUG_NAME_PLACEHOLDER}
              value={drugName}
              onChange={handlePrefixChange}
              onKeyDown={e => {
                if (e.key === 'ArrowDown' && drugNames.length > 0) {
                  drugNameRef.current.focus()
                }
              }}
              clearInput={clearDrugNameInput}
              tabIndex={1}
            />
          </div>
          {resultsMode && !isMd
            ? <div className='c-search-input__zip-btn'>{renderZipAndBtn()}</div>
            : renderZipAndBtn()
          }
          {drugNames.length > 0 || isLoadingDrugNames ? (
            <div className={`
              c-search-input__drugnames
              ${isFrame ? 'c-search-input__drugnames--iframe' : ''}
            `}>
              {drugNames.map((item, i) => (
                <div
                  key={item.drugName}
                  onClick={() => handleSelectDrugName(item.drugName)}
                  onKeyDown={e => {
                    if (e.key === 'Enter') {
                      handleSelectDrugName(item.drugName)
                    } else if (e.key === 'ArrowDown' && i < drugNames.length - 1) {
                      const list = document.getElementsByClassName('c-search-input__drugname')
                      const item = list[i + 1]
                      item.focus()
                    } else if (e.key === 'ArrowUp' && i > 0) {
                      const list = document.getElementsByClassName('c-search-input__drugname')
                      const item = list[i - 1]
                      item.focus()
                    }
                  }}
                  className='c-search-input__drugname'
                  tabIndex={i + 2}
                  {...refProp(i)}
                >
                  {item.drugName}
                </div>
              ))}
              {isLoadingDrugNames && (
                <div className='c-search-input__dropdown-cover'>
                  <Spin />
                </div>
              )}
            </div>
          ) : null}
          <Modal
            visible={showModal}
            onCancel={closeModal}
            className='c-error-modal'
            footer={[]}
          >
            <div className='c-coupon-form'>
              <div className='c-coupon-form__title'>Set Your Location</div>
              <div className='c-coupon-form__sub-title'>
                Enter your zip code below to find a location near you:
              </div>
              <Form onSubmit={(e) => { e.preventDefault(); }}>
                <Row {...rowProps}>
                  <Col {...colMdProps}>
                    <Form.Item name='zip' rules={[{ required: true, message: 'Please enter zip code' }]}>
                      <div class="g-input__form-item">
                        <div class="g-input__label">ZIP CODE</div>
                        <div class="g-input__container">
                          <Input pattern="\d{0,5}" placeholder="Enter zip code" className='g-input' onPressEnter={(e) => window.location.href += `&zip=${e.target.value}`} autoFocus required inputMode="numeric" />
                        </div>
                      </div>
                    </Form.Item>
                  </Col>
                </Row>
                <SecondaryButton className='c-coupon-form__submit m-right' onClick={closeModal}>
                  Cancel
                </SecondaryButton>
                <PrimaryButton className='c-coupon-form__submit' type='submit' onClick={() => submitLocation(document.querySelector('input[placeholder="Enter zip code"]').value)}>
                  Set Location
                </PrimaryButton>
              </Form>
            </div>
          </Modal>
        </form>
      )}
    </Media>
  )
}

export default SearchInput
