import React, { useState, useEffect } from 'react';
import { Dropdown } from 'antd';
import { useHistory } from 'react-router-dom';

export default ({ placement = 'bottomRight', children }) => {
  const [clicked, setClicked] = useState(false);
  const history = useHistory();

  useEffect(() => {
    const unlisten = history.listen(() => {
      // Close the menu when the route changes
      setClicked(false);
      notClickedFunction();
    });

    // Clean up the listener when the component unmounts
    return () => {
      unlisten();
    };
  }, [history]);

  const handleVisibleChange = (visible) => {
    if (!visible) {
      setClicked(false);
      notClickedFunction();
    } else {
      setClicked(true);
    }
  };

  const handleClick = () => {
    setClicked(!clicked);
  };

  const notClickedFunction = () => {
    console.log('Menu closed without clicking anything');
  };

  return (
    <Dropdown
      overlay={children}
      overlayClassName="g-icon__dropdown"
      placement={placement}
      trigger={['click']}
      onVisibleChange={handleVisibleChange}
      visible={clicked} // Add this prop to control the visibility
      getPopupContainer={() => document.body} // Specify a fixed parent element
    >
      <svg
        width="40"
        height="40"
        viewBox="0 0 40 40"
        fill="none"
        onClick={handleClick}
      >
        {clicked ? (
          <path d="M10 10L30 30M30 10L10 30" stroke="#005DAB" strokeWidth="4" />
        ) : (
          <path
            d="M5 30H35V26.6667H5V30ZM5 21.6667H35V18.3333H5V21.6667ZM5 10V13.3333H35V10H5Z"
            fill="#005DAB"
          />
        )}
      </svg>
    </Dropdown>
  );
};
