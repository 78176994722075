import React, { useCallback, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import Media from 'react-media'
import { useHistory } from 'react-router-dom'
import {
  selectResultsForms,
  selectResultsQuantities,
  selectResultsStrengths,
  selectResultsFilters,
  selectLoadingResults,
  selectResultNames,
  selectSearchBrandIndicator
} from 'features/search/redux/searchSelector'
import { DdnInput, DdnSelect, ToggleBtn, CloseIcon, CheckIcon } from 'shared/components'
import { setSearchFilter, findDrugs, setSearchBrandIndicator } from 'features/search/redux/searchActions'
import { QUERY_MD, FORM, STRENGTH, QUANTITY, QTY, GSN, CUSTOM_QUANTITY, BRAND_INDICATOR, GB, BRAND_GENERIC } from 'shared/constants'
import { parseQueryString } from 'utils'
import { setFrameHeight } from 'utils/iframe'
import { debounce } from 'lodash'

const SearchFilters = ({ visible }) => {
  const [showFilter, setShowFilter] = useState(false)
  const forms = useSelector(selectResultsForms)
  const [customQuantity, setCustomQuantity] = useState(undefined);
  const [showCustomQuantity, setShowCustomQuantity] = useState(false);
  const [brandIndicator, setBrandIndicator] = useState("G");
  const [checkCustomQuantity, setCheckCustomQuantity] = useState(false);
  const bgIndicator = useSelector(selectSearchBrandIndicator);

  const quantities = useSelector(selectResultsQuantities)
  const names = useSelector(selectResultNames);
  const genericNames = names?.filter((name) => name.brandGeneric === "G");
  const brandNames = names?.filter((name) => name.brandGeneric === "B");
  const isBrandOnly = names?.length > 0 && genericNames.length === 0;
  const isGenericOnly = names?.length > 0 && brandNames.length === 0;
  let orderedQuantities = [...quantities]
  orderedQuantities.sort((a, b) => {
    return a.quantity - b.quantity
  });
  if (orderedQuantities.length > 0)
    orderedQuantities.push({
      gsn: "",
      isSelected: false,
      quantity: "Custom Quantity",
      quantityLabel: "capsules",
      ranking: orderedQuantities.length
    })

  const strengths = useSelector(selectResultsStrengths)
  let orderedStrengths = [...strengths]
  orderedStrengths.sort((a, b) => {
    return a.strength.match(/^(\d*\.?\d*)/s)[0] - b.strength.match(/^(\d*\.?\d*)/s)[0]
  });

  const isLoadingResults = useSelector(selectLoadingResults)

  const inputs = useSelector(selectResultsFilters)

  const dispatch = useDispatch()
  const history = useHistory()

  useEffect(() => {
    if (bgIndicator !== brandIndicator) {
      setBrandIndicator(bgIndicator);
    }
  }, [bgIndicator, brandIndicator]);

  const handleFilter = useCallback((key, field, value) => {
    const { qty } = inputs
    const newGsn = field === GSN ? value : inputs.gsn
    dispatch(setSearchFilter({ key, field, value, newGsn }))
    const search = history.location.search
    const query = parseQueryString(search)
    const { ..._query } = query
    const newQuery = { ..._query, [field]: value, [GSN]: newGsn }
    if (field !== "brandIndicator") {
      newQuery.brandIndicator = brandIndicator;
    }

    if (key === STRENGTH && qty) {
      Object.assign(newQuery, { qty })
    }

    dispatch(findDrugs(newQuery, key))
  }, [inputs, dispatch, history.location.search, brandIndicator]);

  const updateBrandIndicator = useCallback(
    debounce((val) => {
      dispatch(setSearchBrandIndicator(val))
    }, 300),
    [dispatch]
  )

  useEffect(() => {
    if (isBrandOnly) {
      setBrandIndicator("B");
      updateBrandIndicator("B");
      if (brandIndicator === "G") {
        handleFilter(BRAND_GENERIC, "brandIndicator", "B");
      }
    }
    if (isGenericOnly) {
      setBrandIndicator("G");
      updateBrandIndicator("G");
      if (brandIndicator === "B") {
        handleFilter(BRAND_GENERIC, "brandIndicator", "G");
      }
    }
  }, [isBrandOnly, isGenericOnly, brandIndicator, updateBrandIndicator, handleFilter]);

  const handleChangeFilter = (key, field) => (value) => {
    if (value === "Custom Quantity") {
      setShowCustomQuantity(true);
    } else {
      if (field === 'gb') {
        setBrandIndicator(value)
        updateBrandIndicator(value)
        field = "brandIndicator"
      }
      handleFilter(key, field, value);
    }
  }

  const handleToggleFilters = () => {
    setShowFilter(show => !show)
  }

  useEffect(() => {
    setFrameHeight()
  }, [showFilter])

  useEffect(() => {
    if (checkCustomQuantity && customQuantity) {
      handleFilter(QUANTITY, QTY, customQuantity);
    }
  }, [checkCustomQuantity, customQuantity, handleFilter]);

  const genericBrand = names?.map((name) => ({
    isSelected: name.isSelected,
    brandindicator: (name.brandGeneric === "G" ? "(GENERIC)" : "(BRAND)") + " " + name.drugName + " ",
    gb: name.brandGeneric,
    ranking: 1,
  }))

  const brandOnly = [
    {
      isSelected: true,
      brandindicator: "BRAND",
      gb: "B",
      ranking: 1,
    },
  ]
  const genericOnly = [
    {
      isSelected: true,
      brandindicator: "GENERIC",
      gb: "G",
      ranking: 1,
    },
  ]

  let fields = [
    { key: FORM, field: GSN, items: forms, label: 'FORM' },
    { key: STRENGTH, field: GSN, items: orderedStrengths, label: 'DOSAGE' },
    { key: QUANTITY, field: QTY, items: orderedQuantities, label: 'QUANTITY' },
  ]
  if (orderedQuantities.length > 0) {
    fields.push({ key: BRAND_GENERIC, field: GB, items: isBrandOnly ? brandOnly : isGenericOnly ? genericOnly : genericBrand, label: BRAND_INDICATOR })
  }

  const handleCustomQuantityInputChange = (e) => {
    const value = e.target.value;
    if (value < 0) return;
    if (value > 99999) return;
    setCustomQuantity(value);
    setCheckCustomQuantity(false);
  }

  const disableCustomQuantity = () => {
    setCustomQuantity(undefined);
    setShowCustomQuantity(false);
    setCheckCustomQuantity(false);
  }

  const handleCheckCustomQuantity = () => {
    if (customQuantity) {
      handleFilter(QUANTITY, QTY, customQuantity);
      setCustomQuantity(undefined);
      setShowCustomQuantity(false);
    }
  }

  const handleBlurCustomQuantity = () => {
    if (!customQuantity) {
      setCustomQuantity(undefined);
      setShowCustomQuantity(false);
    }
  }

  const handleSubmitCustomQuantity = (e) => {
    e.preventDefault();
    handleCheckCustomQuantity();
  }

  return (
    <Media queries={{ isMd: QUERY_MD }}>
      {({ isMd }) => (
        <>
          {!isMd && visible ? (
            <>
              <div className='c-results-search__inputs'>
                {fields.map(({ field, key, items }) => {
                  const v = inputs[key]
                  const item = items.find(i => i[field] === v || i[key] === v)
                  return item ? (
                    <div key={key}>
                      {item[key]}
                    </div>
                  ) : null
                })}
              </div>
              <ToggleBtn
                show={showFilter}
                onClick={handleToggleFilters}
                label='Edit Dosage'
                className='c-results-search__edit-show'
              />
            </>
          ) : null}
          {isMd || showFilter ? <div className='c-results-filters'>
            {fields.map(({ key, field, items, label }, i) => {
              let disabled = isLoadingResults;
              let value = inputs[key];
              if (showCustomQuantity && field === QTY) return (
                <div className='g-select' key={key}>
                  <div className='g-select__label'>{CUSTOM_QUANTITY}</div>
                  <CloseIcon className='g-select__close' onClick={disableCustomQuantity} />
                  <CheckIcon className='g-select__checkbox' disabled={!customQuantity} fill={customQuantity ? "#ffffff" : "#000000"} style={customQuantity ? { backgroundColor: "#99ee33" } : { cursor: "not-allowed" }} width={15} onClick={handleCheckCustomQuantity} />
                  <form className="c-results-filter_customquantity" onSubmit={handleSubmitCustomQuantity}>
                    <DdnInput
                      value={customQuantity}
                      onChange={handleCustomQuantityInputChange}
                      onBlur={handleBlurCustomQuantity}
                      tabIndex={3}
                      type="number"
                      style={{ paddingRight: 0, paddingLeft: "0.8rem" }}
                      autoFocus
                    />
                  </form>
                </div>
              );
              if (field === GB) {
                value = items.length === 1 && items[0].gb === "B" && isLoadingResults ? "G" : brandIndicator;
                if (items.length === 1 && items[0].gb === "B" && isLoadingResults)
                  items = genericOnly;
                // if (items.length == 1) disabled = true
              }
              return (
                <DdnSelect
                  key={key}
                  id={key}
                  field={field}
                  value={value}
                  items={items}
                  onSelect={handleChangeFilter(key, field)}
                  label={label}
                  disabled={disabled}
                  tabIndex={i + 20}
                  showArrow={!disabled}
                  placement={'bottomRight'}
                />
              )
            })}
          </div> : null}
        </>
      )}
    </Media>
  )
}

export default SearchFilters
