import React from 'react'

export default ({ width = 28, fill = '#FFFFFF', isDisabled }) => (
  <svg width={`${width}px`} height={`${width}px`} viewBox='0 0 28 28' version="1.1" xmlns="http://www.w3.org/2000/svg">
    <g id="Symbols" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g id="Icons" transform="translate(-1124.000000, -203.000000)" fill={isDisabled ? '#98A0A9' : fill}>
        <g id="Icons/search" transform="translate(1122.000000, 201.000000)">
          <path d="M29.9160172,27.987516 L20.8265116,18.8980104 C22.2370125,17.0745093 23.000013,14.8450079 23.000013,12.5 C23.000013,9.69300475 21.9045123,7.06100312 19.9235111,5.0765019 C17.9425098,3.09200067 15.3035082,2 12.5,2 C9.69650475,2 7.05750312,3.09550068 5.0765019,5.0765019 C3.09200067,7.05750312 2,9.69300475 2,12.5 C2,15.3035082 3.09550068,17.9425098 5.0765019,19.9235111 C7.05750312,21.9080123 9.69300475,23.000013 12.5,23.000013 C14.8450079,23.000013 17.0710093,22.2370125 18.8945104,20.8300116 L27.984016,29.9160172 C28.0378439,29.9698771 28.1108694,30.000138 28.1870162,30.000138 C28.2631629,30.000138 28.3361884,29.9698771 28.3900163,29.9160172 L29.9160172,28.3935163 C29.9698771,28.3396884 30.000138,28.2666629 30.000138,28.1905162 C30.000138,28.1143694 29.9698771,28.0413439 29.9160172,27.987516 Z M18.0440099,18.0440099 C16.560009,19.5245108 14.5930078,20.3400113 12.5000065,20.3400113 C10.4070052,20.3400113 8.44000397,19.5245108 6.95600306,18.0440099 C5.47550214,16.560009 4.66000164,14.5930078 4.66000164,12.5000065 C4.66000164,10.4070052 5.47550214,8.43650397 6.95600306,6.95600306 C8.44000397,5.47550214 10.4070052,4.66000164 12.5000065,4.66000164 C14.5930078,4.66000164 16.563509,5.47200214 18.0440099,6.95600306 C19.5245108,8.44000397 20.3400113,10.4070052 20.3400113,12.5000065 C20.3400113,14.5930078 19.5245108,16.563509 18.0440099,18.0440099 Z" id="Shape"></path>
        </g>
      </g>
    </g>
  </svg>
)
